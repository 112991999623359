const initialState = {
  authToken: null,
  currentUser: null,
  isLoggedIn: false,
  isSupportCoordinator: false,
  isLoggingIn: false,
  loginError: null,
  isUnverifiedAccount: false,
  isAccountVerificationComplete: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        authToken: action.payload,
      };
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.payload,
      };
    case 'SET_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case 'SET_SUPPORT_COORDINATOR':
      return {
        ...state,
        isSupportCoordinator: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        authToken: null,
        currentUser: null,
        isLoggedIn: false,
        isSupportCoordinator: false,
      };
    case 'SET_LOGGING_IN':
      return {
        ...state,
        isLoggingIn: action.payload,
      };
    case 'SET_LOGIN_ERROR':
      return {
        ...state,
        loginError: action.payload,
      };
    case 'SET_UNVERIFIED_ACCOUNT':
      return {
        ...state,
        isUnverifiedAccount: action.payload,
      };
    case 'SET_ACCOUNT_VERIFICATION_STATUS':
      return {
        ...state,
        isAccountVerificationComplete: action.payload,
      };
    default:
      return state;
  }
}
