import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoLogOutOutline, IoMenuOutline } from 'react-icons/io5';
import { mainLinks, aboutLinks, howItWorks, resources, supportLinks } from 'Core/services/sidebar-menu';
import './SidebarNav.scss';

export default function SidebarNav() {
  const { currentUser, isLoggedIn, isSupportCoordinator } = useSelector((store) => store.AuthReducer);
  const [main, setMain] = useState([]);
  const [support, setSupport] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const logout = (e) => {
    dispatch({
      type: 'LOGOUT',
    });

    dispatch({
      type: 'SIDEBAR_TOGGLE',
    });

    e.preventDefault();
  };

  useEffect(() => {
    let commonLinks = [];

    if (isLoggedIn) {
      commonLinks = mainLinks.filter((links) => links.roles.includes(currentUser.role) || links.roles.includes('ALL'));

      setSupport(supportLinks.find((link) => link.id === currentUser.role) || null);
    } else {
      commonLinks = mainLinks.filter((links) => links.roles.includes(null));
    }

    setMain(commonLinks);
  }, [isLoggedIn, currentUser]);

  useEffect(() => {
    const unlisten = history.listen((loc) => {
      if (loc.pathname !== location.pathname) {
        dispatch({
          type: 'SIDEBAR_TOGGLE',
        });
      }
    });

    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, [history, location, dispatch]);

  return (
    <div className="sidebar-nav">
      <section>
        <header>
          <h2>Careseekers</h2>
        </header>

        <ul className="list-unstyled">
          {main.map((link) => (
            <li key={link.id}>
              {link.to && (
                <Link to={link.to}>
                  <span className="icon" aria-hidden="true">
                    {link.icon}
                  </span>

                  {link.label}
                </Link>
              )}

              {link.href && (
                <a href={link.href}>
                  <span className="icon" aria-hidden="true">
                    {link.icon}
                  </span>

                  {link.label}
                </a>
              )}
            </li>
          ))}

          {/* show SC Dashboard menu when logged in as a CS user */}
          {isSupportCoordinator && currentUser.role !== 'SUPPORT' && (
            <li>
              <Link to="/support-coordinator/dashboard">
                <span className="icon" aria-hidden="true">
                  <IoMenuOutline />
                </span>

                SC Dashboard
              </Link>
            </li>
          )}

          {isLoggedIn && (
            <li>
              <a href="/" onClick={logout}>
                <span className="icon" aria-hidden="true">
                  <IoLogOutOutline />
                </span>

                Logout
              </a>
            </li>
          )}
        </ul>
      </section>

      <section>
        <header>
          <h2>About us</h2>
        </header>

        <ul className="list-unstyled">
          {aboutLinks.map((link) => (
            <li key={link.id}>
              <Link to={link.to}>
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <header>
          <h2>How it works</h2>
        </header>

        <ul className="list-unstyled">
          {howItWorks.map((link) => (
            <li key={link.id}>
              <Link to={link.to}>
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <header>
          <h2>Safety & resources</h2>
        </header>

        <ul className="list-unstyled">
          {resources.map((link) => (
            <li key={link.id}>
              <Link to={link.to}>
                {link.label}
              </Link>
            </li>
          ))}

          {support !== null && (
            <li>
              <a href={support.href}>
                {support.label}
              </a>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
}
