import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GTMPageTrack } from 'Core/services/event-tracking';

export default function PageTrack() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      setTimeout(() => {
        GTMPageTrack(document.title, window.location.pathname, window.location.search);
      }, 100); // delay is to get around the off-by-one mismatch between page path and page url in Google Anlytics
      // see: https://github.com/nfl/react-helmet/issues/189 and https://learnedia.com/fix-incorrect-page-title-react-next-js/
    });

    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, [history]);

  return null;
}
