import React from 'react';

import './YourSafetySection.scss';
import backgroundchecks from 'Home/assets/images/YourSafetySection/background-checks.png';
import registrationprocess from 'Home/assets/images/YourSafetySection/Registration-Process.png';
import insurance from 'Home/assets/images/YourSafetySection/Insurance.png';

import SectionHeader from '../SectionHeader/SectionHeader';
import SafetyCard from './SafetyCard/SafetyCard';

export default function YourSafetySection() {
  return (
    <div className="your-safety-section">
      <SectionHeader text="Your safety is a priority" />
      <div className="card-list">
        <SafetyCard
          img={backgroundchecks}
          title="Background Checks"
          description="All care and support workers have relevant checks including police check,
            NDIS worker screening check and WWCC."
        />

        <SafetyCard
          img={registrationprocess}
          title="Registration Process"
          description="All care and support workers must have two references and go through our onboarding process."
        />

        <SafetyCard
          img={insurance}
          title="Insurance"
          description="We have put in place Public Liability insurance
            and accident insurance for all care and support workers
            so you have peace of mind when engaging a care or support
            worker directly."
        />
      </div>
    </div>
  );
}
