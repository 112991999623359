import React from 'react';
import './FormError.scss';

export default function FormError({ message }) {
  let errorMessage = null;

  if (Array.isArray(message)) {
    [errorMessage] = message;
  } else {
    errorMessage = message;
  }

  if (errorMessage) {
    return (
      <div className="form-error">
        <p className="text-danger" role="alert">{errorMessage}</p>
      </div>
    );
  }

  return null;
}
