import React from 'react';
import SectionHeader from 'Home/components/SectionHeader/SectionHeader';
import HelpYouCard from 'Home/components/HelpYouSection/HelpYouCard/HelpYouCard';
import './HelpYouSection.scss';
import becomeSW from 'Home/assets/images/HelpMeSection/become-sw.png';
import providerSearchSW from 'Home/assets/images/HelpMeSection/provider-search-sw.png';
import referClient from 'Home/assets/images/HelpMeSection/refer-client.png';
import searchSW from 'Home/assets/images/HelpMeSection/search-sw.png';

export default function HelpYouSection() {
  return (
    <div className="help-you-section">
      <SectionHeader text="How can we help you?" />
      <div className="card-list">
        <HelpYouCard
          img={searchSW}
          alt="Search"
          text="I want to find a support worker"
          route="/care-workers/search"
        />
        <HelpYouCard
          img={becomeSW}
          alt="Become Worker"
          text="I want to become a support worker"
          route="/careworker"
        />
        <HelpYouCard
          img={referClient}
          alt="Refer"
          text="I want to refer a client or participant"
          route="/referrals"
        />
        <HelpYouCard
          img={providerSearchSW}
          alt="Provider"
          text="I am a provider looking for workers"
          route="/providers"
        />
      </div>
    </div>
  );
}
