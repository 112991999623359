import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { IoPersonCircleOutline } from 'react-icons/io5';

export default function DashboardMenuButton() {
  const { isSidebarOpen } = useSelector((store) => store.SCStore);
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch({
      type: 'SC_SIDEBAR_TOGGLE',
      payload: !isSidebarOpen,
    });
  };

  return (
    <div className="mt-3 mb-3 text-end">
      <Button variant="link" onClick={toggleSidebar} aria-label="My support coordinator account" title="My SC Account">
        <IoPersonCircleOutline size={24} />
      </Button>
    </div>
  );
}
