import React from 'react';

import './WhatCSSection.scss';

import workerGrid from 'assets/img/landing/home/cs_community_grid.jpg';

import SectionHeader from 'Home/components/SectionHeader/SectionHeader';
import { Container } from 'react-bootstrap';

export default function WhatCSSection() {
  return (
    <div className="what-is-cs-section">
      <Container>
        <div className="content">
          <div className="logo-container">
            <img src={workerGrid} alt="CS Community" width="475" height="634" loading="lazy" />
          </div>
          <div className="desc-container">
            <SectionHeader text="What is Careseekers?" />
            <div className="description">
              <p>
                At Careseekers we are more than just a platform. We connect individuals, families and
                organisations directly to disability support workers and aged care workers. We are an
                NDIS registered provider and can service all NDIS participants. We partner with aged
                care providers so you can use your home care funding on a care worker you choose.
              </p>

              <p>We value, choice, control and affordability.</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
