const initialState = {
  shiftNotes: [],
  newShiftNote: null,
  isLoading: false,
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  shiftNoteError: null,
  shiftNoteSaved: false,
  allShiftNotesLoaded: false,
  createError: null,
};

export default function ShiftNoteStore(state = initialState, action) {
  switch (action.type) {
    case 'NEW_SHIFT_NOTE':
      return {
        ...state,
        shiftNoteSaved: true,
        newShiftNote: action.payload,
        shiftNotes: [action.payload, ...state.shiftNotes],
      };
    case 'RESET_NEW_SHIFT_NOTE':
      return {
        ...state,
        shiftNoteSaved: false,
        newShiftNote: null,
      };
    case 'SET_SHIFT_NOTES':
      return {
        ...state,
        shiftNotes: action.payload,
      };
    case 'APPEND_SHIFT_NOTES':
      return {
        ...state,
        shiftNotes: state.shiftNotes.concat(action.payload),
      };
    case 'UPDATE_SHIFT_NOTE':
      // eslint-disable-next-line no-case-declarations
      const { shiftNotes } = state;
      // eslint-disable-next-line no-case-declarations
      const updatedShiftNotes = shiftNotes.map((s) => {
        if (s.id === action.payload.id) {
          return action.payload;
        }

        return s;
      });

      return {
        ...state,
        shiftNotes: updatedShiftNotes,
      };
    case 'REMOVE_SHIFT_NOTE':
      return {
        ...state,
        shiftNotes: state.shiftNotes.filter((note) => note.id !== action.payload),
      };
    case 'SHIFT_NOTE_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SHIFT_NOTES_FETCHING':
      return {
        ...state,
        isFetching: action.payload,
      };
    case 'SHIFT_NOTE_UPDATING':
      return {
        ...state,
        isUpdating: action.payload,
      };
    case 'SHIFT_NOTE_DELETING':
      return {
        ...state,
        isDeleting: action.payload,
      };
    case 'SHIFT_NOTES_ALL_FETCHED':
      return {
        ...state,
        allShiftNotesLoaded: action.payload,
      };
    case 'CREATE_SHIFT_NOTE_ERROR':
      return {
        ...state,
        createError: action.payload,
      };
    case 'SHIFT_NOTE_ERROR':
      return {
        ...state,
        shiftNoteError: action.payload,
      };
    case 'RESET_CREATE_SHIFT_NOTE_ERROR':
      return {
        ...state,
        createError: null,
      };
    case 'RESET_SHIFT_NOTE_ERROR':
      return {
        ...state,
        createError: null,
        shiftNoteError: null,
      };
    default:
      return state;
  }
}
