import React from 'react';

import './HowCSWorkSection.scss';

import becomeSW from 'Home/assets/images/HelpMeSection/become-sw.png';
import providerSearchSW from 'Home/assets/images/HelpMeSection/provider-search-sw.png';
import referClient from 'Home/assets/images/HelpMeSection/refer-client.png';
import searchSW from 'Home/assets/images/HelpMeSection/search-sw.png';

import SectionHeader from '../SectionHeader/SectionHeader';
import WorkCard from './WorkCard/WorkCard';

export default function HowCSWorkSection() {
  return (
    <div className="how-cs-work-section">
      <SectionHeader text="How Careseekers works" />
      <div className="card-list">
        <WorkCard
          img={searchSW}
          alt="Search"
          title="Find a worker"
          description="Post your job, search our online community of care and support workers."
        />
        <WorkCard
          img={becomeSW}
          alt="Connect"
          title="Connect"
          description="Communicate with workers through our messaging system and interview them."
        />
        <WorkCard
          img={referClient}
          alt="Manage and Pay Online"
          title="Manage and Pay Online"
          description="Our invoicing and payments system is secure and easy to use."
        />
        <WorkCard
          img={providerSearchSW}
          alt="Build your supports"
          title="Build your team of supports"
          description="Careseekers is there for you when you need to grow your team,
          find someone to fill in if your worker is going away or need someone with a different skill set."
        />
      </div>
    </div>
  );
}
