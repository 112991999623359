import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const AsyncPostJob = lazy(() => import('Job/containers/PostJob'));
const MyJobs = lazy(() => import('Job/containers/Jobs'));
const UpdateJob = lazy(() => import('Job/pages/UpdateJob'));
const Unsubscribe = lazy(() => import('Job/pages/UnsubscribeNearbyJobs'));

export default [
  <Route path="/careseeker/post-a-job" exact component={AsyncPostJob} />,

  <GuardedRoute allowedRoles={['CS']} path="/my-job-posts" exact>
    <MyJobs />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CS']} path="/edit-job/:jobId" exact>
    <UpdateJob />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/my-jobs" exact>
    <MyJobs />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/carer/unsubscribe/nearbyjobs" exact>
    <Unsubscribe />
  </GuardedRoute>,
];
