import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const CSDashboard = lazy(() => import('CareSeeker/containers/Dashboard'));
const InvoicesAndHours = lazy(() => import('CareSeeker/pages/InvoicesAndHours'));
const ManageAccountPage = lazy(() => import('CareSeeker/pages/ManageAccount'));
const AccountDetailsPage = lazy(() => import('CareSeeker/pages/AccountDetails'));
const ProviderDetailsPage = lazy(() => import('CareSeeker/pages/ProviderDetails'));
const MyWorkersPage = lazy(() => import('CareSeeker/pages/MyWorkers'));

export default [
  <GuardedRoute allowedRoles={['CS']} path="/dashboard" exact>
    <CSDashboard />
  </GuardedRoute>,
  <GuardedRoute allowedRoles={['CS']} path="/invoices-and-hours" exact>
    <InvoicesAndHours />
  </GuardedRoute>,
  <GuardedRoute allowedRoles={['CS']} path="/my-account" exact>
    <ManageAccountPage />
  </GuardedRoute>,
  <GuardedRoute allowedRoles={['CS']} path="/account-details" exact>
    <AccountDetailsPage />
  </GuardedRoute>,
  <GuardedRoute allowedRoles={['CS']} path="/provider-details" exact>
    <ProviderDetailsPage />
  </GuardedRoute>,
  <GuardedRoute allowedRoles={['CS']} path="/my-workers" exact>
    <MyWorkersPage />
  </GuardedRoute>,
];
