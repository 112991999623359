import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const UnpaidInvoices = lazy(() => import('Invoice/pages/UnpaidInvoices'));
const PastInvoices = lazy(() => import('Invoice/pages/PastInvoices'));
const PlatformFee = lazy(() => import('Invoice/pages/PlatformFee'));

export default [
  <GuardedRoute allowedRoles={['CR']} path="/invoices" exact>
    <UnpaidInvoices />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CS']} path="/pending-invoices" exact>
    <UnpaidInvoices />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/paid-invoices" exact>
    <PastInvoices />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CS']} path="/past-invoices" exact>
    <PastInvoices />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CS', 'CR']} path="/platform-fee" exact>
    <PlatformFee />
  </GuardedRoute>,
];
