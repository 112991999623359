import React from 'react';
import './ReadyHelpCard.scss';

export default function ReadyHelpCard({ img, title }) {
  return (
    <li className="ready-help-card">
      <img src={img} alt="" loading="lazy" width="250" height="250" />

      <span className="title">{title}</span>
    </li>
  );
}
