import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const MessagesPage = lazy(() => import('Message/pages/Messages'));

export default [
  <GuardedRoute allowedRoles={['CS', 'CR']} path="/messages" exact>
    <MessagesPage />
  </GuardedRoute>,
];
