const initialState = {
  isSidebarOpen: false,
};

export default function SCStore(state = initialState, action) {
  switch (action.type) {
    case 'SIDEBAR_TOGGLE':
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    default:
      return state;
  }
}
