const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  dateOfIncident: null,
  timeOfIncident: null,
  location: '',
  witnesses: '',
  involved: '',
  details: '',
  injuries: '',
  concerns: '',
  actionTaken: '',
  favourableOutcome: '',
};

export default function FeedbackStore(state = initialState, action) {
  switch (action.type) {
    case 'FEEDBACK_FNAME':
      return {
        ...state,
        firstName: action.payload,
      };
    case 'FEEDBACK_LNAME':
      return {
        ...state,
        lastName: action.payload,
      };
    case 'FEEDBACK_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'FEEDBACK_PHONE':
      return {
        ...state,
        phone: action.payload,
      };
    case 'FEEDBACK_ROLE':
      return {
        ...state,
        role: action.payload,
      };
    case 'FEEDBACK_DATE':
      return {
        ...state,
        dateOfIncident: action.payload,
      };
    case 'FEEDBACK_TIME':
      return {
        ...state,
        timeOfIncident: action.payload,
      };
    case 'FEEDBACK_LOCATION':
      return {
        ...state,
        location: action.payload,
      };
    case 'FEEDBACK_WITNESSES':
      return {
        ...state,
        witnesses: action.payload,
      };
    case 'FEEDBACK_INVOLVED':
      return {
        ...state,
        involved: action.payload,
      };
    case 'FEEDBACK_DETAILS':
      return {
        ...state,
        details: action.payload,
      };
    case 'FEEDBACK_INJURIES':
      return {
        ...state,
        injuries: action.payload,
      };
    case 'FEEDBACK_CONCERNS':
      return {
        ...state,
        concerns: action.payload,
      };
    case 'FEEDBACK_ACTION_TAKEN':
      return {
        ...state,
        actionTaken: action.payload,
      };
    case 'FEEDBACK_FAVOURABLE_OUTCOME':
      return {
        ...state,
        favourableOutcome: action.payload,
      };
    case 'FEEDBACK_RESET':
      return { ...initialState };
    default:
      return state;
  }
}
