import React from 'react';
import './GetStartedCard.scss';

export default function GetStartedCard({ title, description, children }) {
  return (
    <div className="get-started-card">
      <h1>{title}</h1>

      <p className="description">{description}</p>

      {children !== undefined && (
        <>
          {children}
        </>
      )}
    </div>
  );
}
