import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineLinkedin } from 'react-icons/ai';
import { FiTwitter } from 'react-icons/fi';
import NewsletterSub from 'Core/components/NewsletterSub';
import './Footer.scss';
import logoWhite from 'assets/img/logo/logo_rev.png';
import ndisLogo from 'assets/img/icons/i_heart_ndis.png';

export default function Footer() {
  const { currentUser, isLoggedIn } = useSelector((store) => store.AuthReducer);

  return (
    <footer id="cs-footer">
      <Container>
        <Row className="mt-2 mb-5">
          <Col xs={{ span: 12, offset: 0 }} className="text-center">
            <Link to="/" aria-label="Careseekers home page" className="logo">
              <img src={logoWhite} alt="" className="img-fluid" width="243" height="18" />
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 10, offset: 1 }} lg={{ span: 4, offset: 0 }}>
            <p className="pledge">
              At Careseekers we are more than just a platform. We connect individuals directly to disability
              support workers and aged care workers. We are an NDIS registered provider and can service all
              NDIS participants. We partner with aged care providers so you can use your home care funding
              on a care worker you choose. We value, choice, control and affordability. Find a care or
              support worker that's right for you today.
            </p>

            <p className="mt-5 mb-0 pb-0 fw-bold">Want help?</p>

            <p>
              <Link to="/contact-us">Contact us</Link> or <a href="tel:1300765465">call us on 1300 765 465</a>
            </p>

            <div className="pb-4 mb-4 text-start">
              <img src={ndisLogo} alt="Registered NDIS provider" className="img-fluid" width="180" height="125" />
            </div>
          </Col>

          <Col xs={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 0 }}>
            <Row>
              <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} className="my-2">
                <ul className="list-unstyled">
                  {(!isLoggedIn || currentUser.role === 'CS') && (
                    <li>
                      <Link to="/care-workers/search">Find a Support Worker</Link>
                    </li>
                  )}

                  <li>
                    <Link to="/services/mental-health-support-workers">Find a Mental Health Support Worker</Link>
                  </li>

                  {(!isLoggedIn || currentUser.role === 'CS') && (
                    <li>
                      <Link to="/careseeker/post-a-job">Post your care needs</Link>
                    </li>
                  )}

                  {!isLoggedIn && (
                    <li>
                      <Link to="/login">Sign In</Link>
                    </li>
                  )}

                  <li>
                    <Link to="/careworker">Become a Care Worker</Link>
                  </li>

                  <li>
                    <Link to="/services/disability-support-workers">Find a disability support worker</Link>
                  </li>

                  <li>
                    <Link to="/services/aged-care-workers">Find an aged care worker</Link>
                  </li>
                </ul>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} className="my-2">
                <ul className="list-unstyled">
                  <li>
                    <Link to="/founders">The Founders</Link>
                  </li>

                  <li>
                    <Link to="/media-centre">Media Centre</Link>
                  </li>

                  <li>
                    <a href="https://blog.careseekers.com.au">Blog</a>
                  </li>

                  <li>
                    <a href="https://careseekers.nationalcrimecheck.com.au/" target="_blank" rel="noopener noreferrer">
                      Police Checks
                    </a>
                  </li>

                  <li>
                    <Link to="/insurance-policy">Insurances</Link>
                  </li>

                  <li>
                    <Link to="/testimonials">Testimonials</Link>
                  </li>
                </ul>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }} className="my-2">
                <ul className="list-unstyled">
                  <li>
                    <Link to="/code-of-conduct">Code of Conduct</Link>
                  </li>

                  <li>
                    <Link to="/customer-service-charter">Charter of Customer Service</Link>
                  </li>

                  <li>
                    <Link to="/police-check-policy">Police Check Policy</Link>
                  </li>

                  <li>
                    <Link to="/protection-of-human-rights-freedom-from-abuse-and-neglect">
                      Protection of Human Rights
                    </Link>
                  </li>

                  <li>
                    <Link to="/complaints-policy">Complaints Policy</Link>
                  </li>
                </ul>

                <section className="acknowledgement mt-5">
                  <header>
                    <h1 className="mb-0 h5 fw-bold">Acknowledgement of Country</h1>
                  </header>

                  <p className="pledge">
                    Careseekers acknowledges the Traditional Owners of Country throughout Australia. We pay our
                    respects to Elders past, present and emerging.
                  </p>
                </section>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="socials">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 2 }}>
            <NewsletterSub />
          </Col>

          <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 0 }} className="social">
            <ul className="list-unstyled list-inline list-group list-group-horizontal">
              <li className="list-group-item">
                {/* eslint-disable jsx-a11y/control-has-associated-label */}
                <a
                  href="https://www.facebook.com/careseekersforfamilies/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow Careseekers on Facebook"
                >
                  <AiOutlineFacebook size="24" color="#fff" />
                </a>
              </li>

              <li className="list-group-item">
                <a
                  href="https://www.instagram.com/careseekers_aus/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow Careseekers on Instagram"
                >
                  <AiOutlineInstagram size="24" color="#fff" />
                </a>
              </li>

              <li className="list-group-item">
                <a
                  href="https://www.linkedin.com/company/careseekers"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow Careseekers on LinkedIn"
                >
                  <AiOutlineLinkedin size="24" color="#fff" />
                </a>
              </li>

              <li className="list-group-item">
                <a
                  href="https://twitter.com/Careseekers_Aus"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Follow Careseekers on Twitter"
                >
                  <FiTwitter size="24" color="#fff" />
                </a>
              </li>
            </ul>

            <ul className="list-unstyled text-end">
              <li>
                <Link to="/terms-conditions">Terms and Conditions</Link>
              </li>

              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
