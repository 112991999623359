import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import validate from 'validate.js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormError from 'Core/components/FormError';
import AlertMessage from 'Core/components/AlertMessage';
import ResendVerification from 'Auth/containers/ResendVerification';
import { verifyEmail } from 'Auth/services';

const constraints = {
  code: {
    presence: {
      allowEmpty: false,
      message: 'Enter the verification code sent to your email address',
    },
  },
};

const codeValidator = (data) => validate(data, constraints, { fullMessages: false });

export default function VerifyEmail({ email, userRole, onVerified }) {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const onSubmit = async (event) => {
    const errors = codeValidator({ code });

    setIsLoading(true);
    setAlertMessage({});
    event.preventDefault();

    if (errors) {
      setFormError(errors);
      setIsLoading(false);

      return false;
    }

    try {
      await verifyEmail(email, code);

      setAlertMessage({
        type: 'success',
        message: 'You have successfully verified your email address. You can now login and '
          + 'start using Careseekers!',
      });

      setCode('');
      dispatch({
        type: 'SET_LOGIN_ERROR',
        payload: null,
      });

      setTimeout(() => {
        dispatch({
          type: 'SET_UNVERIFIED_ACCOUNT',
          payload: false,
        });

        dispatch({
          type: 'SET_ACCOUNT_VERIFICATION_STATUS',
          payload: true,
        });

        onVerified();
      }, 4000);
    } catch (e) {
      setAlertMessage({
        type: 'danger',
        message: 'Invalid verification code. Please double check the verification code sent to '
          + 'your email address and try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="email-verification">
      <p className="text-center mt-4 mb-4">
        Please enter the verification code that was sent to your email address.
      </p>

      <Form onSubmit={onSubmit} noValidate>
        <Form.Group controlId="code" className="mb-3">
          <Form.Label>Verification code</Form.Label>

          <Form.Control
            type="text"
            name="code"
            placeholder="e.g. 437127"
            required
            value={code}
            isInvalid={formError.code}
            onChange={(e) => setCode(e.target.value)}
            onFocus={() => setFormError({})}
          />

          <FormError message={formError.code} />
        </Form.Group>

        {(alertMessage && alertMessage.message) && (
          <AlertMessage error={alertMessage} />
        )}

        <div className="text-center">
          <Button
            type="submit"
            variant="primary"
            size="md"
            disabled={isLoading}
            className={isLoading ? 'is-loading' : ''}
          >
            Verify Email
          </Button>
        </div>
      </Form>

      <div className="cta">
        <ResendVerification email={email} userRole={userRole} />
      </div>
    </div>
  );
}
