import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './ModalDialog.scss';

export default function ModalDialog({
  title, message, type, confirmText, cancelText, variant, show, backdrop, keyboard, onConfirm, onDismiss,
}) {
  const confirmationText = confirmText || 'OK';
  const cancellationText = cancelText || 'Dismiss';
  const confButtonClass = variant || 'danger';
  const modalType = type || 'alert';

  return (
    <div className="modal-dialog">
      <Modal
        show={show}
        backdrop={backdrop || true}
        keyboard={keyboard || true}
        onHide={onDismiss}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {message}
          </p>
        </Modal.Body>

        <Modal.Footer>
          {modalType === 'confirm' && (
            <>
              <Button
                type="button"
                variant={confButtonClass}
                onClick={onConfirm}
              >
                {confirmationText}
              </Button>

              <Button type="button" variant="link" onClick={onDismiss}>{cancellationText}</Button>
            </>
          )}

          {modalType === 'alert' && (
            <Button type="button" className="btn btn-primary" onClick={onDismiss}>{cancelText || 'OK'}</Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
