const initialState = {
  cards: null,
  isLoading: false,
  isProcessing: false,
  isPaymentSuccessful: false,
  isCardAdded: false,
  cardError: null,
};

export default function StripeStore(state = initialState, action) {
  switch (action.type) {
    case 'STRIPE_CARDS':
      return {
        ...state,
        cards: action.payload,
      };
    case 'STRIPE_CARDS_RESET':
      return {
        ...state,
        cards: null,
      };
    case 'STRIPE_CARDS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'STRIPE_PROCESSING':
      return {
        ...state,
        isProcessing: action.payload,
      };
    case 'STRIPE_PAYMENT_SUCCESS':
      return {
        ...state,
        isPaymentSuccessful: action.payload,
      };
    case 'STRIPE_CARD_ADDED':
      return {
        ...state,
        isCardAdded: action.payload,
      };
    case 'STRIPE_CARDS_ERROR':
      return {
        ...state,
        cardError: action.payload,
      };
    case 'STRIPE_CARDS_ERROR_RESET':
      return {
        ...state,
        cardError: null,
      };
    default:
      return state;
  }
}
