import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import AlertMessage from 'Core/components/AlertMessage';
import { resendVerificationEmail } from 'Auth/services';
import { GTMTrackEvent } from 'Core/services/event-tracking';

export default function ResendEmailVerification({ email, userRole }) {
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});

  const onClick = async () => {
    setIsLoading(true);
    setAlertMessage({});

    try {
      await resendVerificationEmail(email);

      setAlertMessage({
        type: 'success',
        message: 'We have re-sent the verification code to your email address with instructions to '
          + 'verify your account.',
      });

      if (userRole === 'CR') {
        GTMTrackEvent('Resend Verification', { category: 'Careworker Registration' });
      } else if (userRole === 'CS') {
        GTMTrackEvent('Resend Verification', { category: 'Care Seeker Registration' });
      }

      setTimeout(() => {
        setAlertMessage({});
      }, 5000);
    } catch (e) {
      setAlertMessage({
        type: 'danger',
        message: 'Our servers are experiencing some issues and we were unable to re-send the '
          + 'verification code to your email address. Please try again in a little bit. We '
          + 'regret the inconvenience.',
      });

      setTimeout(() => {
        setAlertMessage({});
      }, 10000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <hr />

      <p className="text-center mt-5">
        Did not receive the verification code? Please choose one of the folowing options:
      </p>

      <ul className="list-unstyled text-center">
        <li className="py-2">
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            onClick={onClick}
            disabled={isLoading}
            className={isLoading ? 'is-loading' : ''}
          >
            Resend Verification Email
          </Button>
        </li>

        <li className="py-2">
          <Link to="/contact" target="_blank" className="btn btn-sm btn-outline-primary">Contact Support</Link>
        </li>
      </ul>

      {(alertMessage && alertMessage.message) && (
        <AlertMessage error={alertMessage} />
      )}
    </>
  );
}
