import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const DisabilitySWPage = lazy(() => import('Services/pages/DisabilitySWPage'));
const MentalHealthSWPage = lazy(() => import('Services/pages/MentalHealthSWPage'));

export default [
  <Route path="/services/disability-support-workers" exact component={DisabilitySWPage} />,
  <Route path="/services/mental-health-support-workers" exact component={MentalHealthSWPage} />,
];
