import { client as axios } from 'store';

const subscribe = async (options) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: '/support',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      data: {
        email: options.email,
        userType: options.subscriptionType,
        message: 'Newsletter subscribed',
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export default subscribe;
