import React, { useState } from 'react';
import validate from 'validate.js';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { IoChevronBackOutline } from 'react-icons/io5';
import AlertMessage from 'Core/components/AlertMessage';
import FormError from 'Core/components/FormError';
import { loginConstraints } from 'Auth/services/validations';
import './LoginForm.scss';
import csLogo from 'assets/img/logo/logo_main_lg.png';

const loginValidator = (data) => validate(data, loginConstraints, { fullMessages: false });

export default function LoginForm({ isLoggingIn, loginError, authError, onBack, onSubmit }) {
  const [creds, setCreds] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;

    setCreds((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submit = (event) => {
    const validation = loginValidator(creds);

    if (!validation) {
      setFormErrors({});
      onSubmit(creds.email, creds.password);
    } else {
      setFormErrors(validation);
    }

    event.preventDefault();
  };

  return (
    <div className="cs-login">
      <img src={csLogo} width="341" height="26" alt="" />

      {authError && (
        <Alert variant="danger" className="alert-message mt-4 mb-0">
          <p className="text-center mb-0">{authError}</p>
        </Alert>
      )}

      <Form onSubmit={submit} noValidate className="mt-5">
        <Form.Group controlId="email" className="mb-4">
          <Form.Label>Email address</Form.Label>

          <Form.Control
            type="email"
            placeholder="Email address"
            size="lg"
            required
            name="email"
            value={creds.email}
            isInvalid={formErrors.email}
            onChange={(e) => handleChange(e)}
            onFocus={() => setFormErrors({ ...formErrors, email: undefined })}
          />

          <FormError message={formErrors.email} />
        </Form.Group>

        <Form.Group controlId="password" className="mb-4">
          <Form.Label>Password</Form.Label>

          <Form.Control
            type="password"
            placeholder="Password"
            size="lg"
            required
            name="password"
            isInvalid={formErrors.password}
            value={creds.password}
            onChange={(e) => handleChange(e)}
            onFocus={() => setFormErrors({ ...formErrors, password: undefined })}
          />

          <FormError message={formErrors.password} />
        </Form.Group>

        <AlertMessage error={loginError} />

        <div className="text-center">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            disabled={isLoggingIn}
            className={isLoggingIn ? 'is-loading' : ''}
          >
            Sign In
          </Button>
        </div>
      </Form>

      <div className="form-actions">
        <ul className="list-unstyled list-inline">
          <li className="list-inline-item">
            <Link to="/careseeker/post-a-job">Register as a care seeker</Link>
          </li>

          <li className="list-inline-item">
            <Link to="/careworker/register">Register as a care worker</Link>
          </li>

          <li className="list-inline-item">
            <Link to="/forgot-password">Forgot password</Link>
          </li>
        </ul>
      </div>

      <div className="mt-5 text-center">
        <Button
          type="button"
          variant="light"
          size="lg"
          onClick={onBack}
          disabled={isLoggingIn}
        >
          <IoChevronBackOutline />
          Back
        </Button>
      </div>
    </div>
  );
}
