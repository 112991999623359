import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoutes from 'Auth/routes';
import CoreRoutes from 'Core/routes';
import CarerRoutes from 'Carer/routes';
import CareSeekerRoutes from 'CareSeeker/routes';
import JobRoutes from 'Job/routes';
import JobListingRoutes from 'JobList/routes';
import JobSearchRoutes from 'JobSearch/routes';
import InvoiceRoutes from 'Invoice/routes';
import TimesheetRoutes from 'Timesheet/routes';
import HomeRoutes from 'Home/routes';
import SCRoutes from 'SupportCoordinator/routes';
import MessageRoutes from 'Message/routes';
import ProvidersRoutes from 'Providers/routes';
import ReferralsRoutes from 'Referrals/routes';
import SafetyResourcesRoutes from 'SafetyResources/routes';
import AgeCareWorkerRoutes from 'Services/routes';
import AboutUsRoutes from 'AboutUs/routes';
import FeedbackRoutes from 'Feedback/routes';
import ShiftNoteRoutes from 'ShiftNote/routes';
import BookingRoutes from 'Booking/routes';
import CarerSearchRoutes from 'CarerSearch/routes';
import ContractRoutes from 'Contract/routes';
import StaticPageRoutes from 'StaticPages/routes';

export default function Routes() {
  return (
    <Switch>
      {AuthRoutes}
      {CoreRoutes}
      {HomeRoutes}
      {CarerRoutes}
      {CareSeekerRoutes}
      {JobRoutes}
      {JobSearchRoutes}
      {JobListingRoutes}
      {InvoiceRoutes}
      {TimesheetRoutes}
      {SCRoutes}
      {MessageRoutes}
      {ProvidersRoutes}
      {ReferralsRoutes}
      {AgeCareWorkerRoutes}
      {SafetyResourcesRoutes}
      {AboutUsRoutes}
      {FeedbackRoutes}
      {ShiftNoteRoutes}
      {BookingRoutes}
      {CarerSearchRoutes}
      {ContractRoutes}
      {StaticPageRoutes}
    </Switch>
  );
}
