import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import HeaderNav from 'Core/components/HeaderNav';
import Footer from 'Core/components/Footer';
import GetStartedSection from 'Home/components/GetStartedSection/GetStartedSection';
import HelpYouSection from 'Home/components/HelpYouSection/HelpYouSection';
import WhatCSSection from 'Home/components/WhatCSSection/WhatCSSection';
import HowCSWorkSection from 'Home/components/HowCSWorkSection/HowCSWorkSection';
import ReadyHelpSection from 'Home/components/ReadyHelpSection/ReadyHelpSection';
import WatchMoneySection from 'Home/components/WatchMoneySection/WatchMoneySection';
import YourSafetySection from 'Home/components/YourSafetySection/YourSafetySection';
import WorkerSection from 'Home/components/WorkerSection/WorkerSection';
import CSCommunitySection from 'Home/components/CSCommunitySection/CSCommunitySection';
import { homePage } from 'metadata';
import './HomePage.scss';

export default function HomePage() {
  return (
    <div className="home-page-container">
      <Helmet>
        <meta name="description" content={homePage.description} />
        <meta name="og:title" content={homePage.title} />
        <meta name="og:description" content={homePage.description} />
        <meta name="og:image" content={homePage.image} />

        <title>{homePage.title}</title>
      </Helmet>

      <HeaderNav />

      <main>
        <div>
          <Container fluid className="g-0">
            <GetStartedSection />
          </Container>
        </div>
        <div>
          <Container>
            <HelpYouSection />
          </Container>
        </div>
        <div>
          <WhatCSSection />
        </div>
        <div>
          <Container>
            <HowCSWorkSection />
          </Container>
        </div>
        <div>
          <Container>
            <ReadyHelpSection title="Care and support workers are ready to help you with" />
          </Container>
        </div>
        <div>
          <Container>
            <WatchMoneySection />
          </Container>
        </div>
        <div>
          <Container>
            <YourSafetySection />
          </Container>
        </div>
        <div>
          <Container>
            <WorkerSection header="Meet our care and support workers" />

            <div className="my-4 text-center">
              <Link to="/workers" className="btn btn-primary btn-lg">
                Learn more about our workers
              </Link>
            </div>
          </Container>
        </div>
        <div>
          <Container>
            <CSCommunitySection />
          </Container>
        </div>
      </main>

      <Footer />
    </div>
  );
}
