import React from 'react';
import './LoadingRipple.scss';

export default function LoadingRipple({ message }) {
  return (
    <div className="loading-ripple">
      <div className="ripple">
        <div />
        <div />
      </div>

      {message && (
        <p>{message}</p>
      )}
    </div>
  );
}
