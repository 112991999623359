const MAX_TRIES = 30;
let hasInitialized = false;
let interval;
let tries;

export const identify = (user) => {
  if (user) {
    window.FS.identify(user.id, {
      displayName: user.name,
      email: user.email,
      userType_str: user.role,
    });
  }
};

export const destroy = () => {
  if (window.FS) {
    hasInitialized = false;

    window.FS.shutdown();
    window.FS.clearUserCookie();
  }
};

export const restart = () => {
  if (window.FS) {
    hasInitialized = false;

    window.FS.restart();

    hasInitialized = true;
  }
};

export const initialize = () => {
  if (!window.FS && !interval) {
    interval = setInterval(() => {
      tries += 1;

      if (window.FS) {
        initialize();
        clearInterval(interval);
      }

      if (tries === MAX_TRIES) {
        clearInterval(interval);
      }
    }, 1000);

    return false;
  }

  if (window.FS) {
    if (process.env.REACT_APP_ENV === 'production') {
      hasInitialized = true;
    } else {
      // no tracking on dev and uat environments
      hasInitialized = false;

      destroy();
    }
  }
};

export const isInitialized = () => hasInitialized;
