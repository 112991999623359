import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const PendingTimesheets = lazy(() => import('Timesheet/pages/PendingTimesheets'));
const SavedTimesheets = lazy(() => import('Timesheet/pages/SavedTimesheets'));
const SubmitTimesheet = lazy(() => import('Timesheet/pages/SubmitTimesheet'));

export default [
  <GuardedRoute allowedRoles={['CS', 'CR']} path="/unapproved-invoices" exact>
    <PendingTimesheets />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/saved-hours" exact>
    <SavedTimesheets />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/submit-timesheet" exact>
    <SubmitTimesheet />
  </GuardedRoute>,
];
