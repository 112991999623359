import validate from 'validate.js';

export const loginConstraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: 'Enter your email address',
    },

    email: {
      message: 'Enter a valid email address',
    },
  },

  password: {
    presence: {
      allowEmpty: false,
      message: 'Enter your password',
    },
  },
};

export const carerSignupConstraints = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'Tell us your first name',
    },
  },

  email: {
    presence: {
      allowEmpty: false,
      message: 'Enter your email address',
    },

    email: {
      message: 'Enter a valid email address',
    },
  },

  password: {
    length: {
      minimum: 8,
      message: 'Password must be at least 8 characters in length',
    },
  },

  phone: {
    presence: {
      allowEmpty: false,
      message: 'Enter your phone number',
    },
  },

  address: {
    presence: {
      allowEmpty: false,
      message: 'Enter your street address',
    },
  },

  suburb: {
    presence: {
      allowEmpty: false,
      message: 'Enter your suburb',
    },
  },

  state: {
    presence: {
      allowEmpty: false,
      message: 'Enter your state',
    },
  },

  postcode: {
    presence: {
      allowEmpty: false,
      message: 'Enter your postcode',
    },
  },

  hearAboutUs: {
    presence: {
      allowEmpty: false,
      message: 'Please let us know how you came across Careseekers',
    },
  },

  acceptTerms: {
    presence: {
      allowEmpty: false,
      message: 'You must accept our terms & conditions',
    },

    inclusion: {
      within: [true],
      message: 'You must accept our terms & conditions',
    },
  },

  acceptEng: {
    presence: {
      allowEmpty: false,
      message: 'You must read and understand the terms of engagement',
    },

    inclusion: {
      within: [true],
      message: 'You must read and understand the terms of engagement',
    },
  },
};

export const carerSignupValidator = (data, constraints) => validate(data, constraints, { fullMessages: false });
