import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { redirectUser } from 'Auth/services';

export default function GuardedRoute({ allowedRoles, children, ...rest }) {
  const { isLoggedIn, currentUser } = useSelector((store) => store.AuthReducer);
  const location = useLocation();

  const getRedirectPath = () => {
    const path = redirectUser(currentUser ? currentUser.role : '');

    return path;
  };

  if (isLoggedIn) {
    if (allowedRoles.indexOf(currentUser.role) > -1) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route {...rest}>
          {children}
        </Route>
      );
    }

    // user role not permitted, redirect to home
    return (
      <Redirect to={{ pathname: getRedirectPath() }} />
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        search: location.search,
        state: {
          redirectTo: location.pathname,
          message: 'Please login with your Careseekers account to continue.',
          showLogin: true,
        },
      }}
    />
  );
}
