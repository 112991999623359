import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function AppInit() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      const keys = [
        'id', 'firstName', 'lastName',
        'name', 'email', 'role',
        'authToken', 'superUserLogin', 'freshchatId',
      ];
      const currentUser = {};

      keys.forEach((key) => {
        currentUser[key] = localStorage.getItem(key);
      });

      // For when support coordinators forget to log out
      // of a linked client account. When they come back,
      // avoid setting the wrong token in axios interceptor.
      localStorage.removeItem('alias_aliasToken');

      dispatch({ type: 'SET_LOGGED_IN', payload: true });
      dispatch({ type: 'SET_CURRENT_USER', payload: currentUser });
      dispatch({ type: 'SET_TOKEN', payload: currentUser.authToken });

      if (currentUser.role === 'SUPPORT') {
        dispatch({ type: 'SET_SUPPORT_COORDINATOR', payload: true });
      }

      if (window.dataLayer) {
        window.dataLayer.push({ user_id: currentUser.id });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
