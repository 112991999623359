import React from 'react';
import './WorkCard.scss';

export default function WorkCard({ img, alt, title, description }) {
  return (
    <article className="work-card">
      <img src={img} alt={alt} width="100" height="100" loading="lazy" />

      <header>
        <h2 className="title">{title}</h2>
      </header>

      <p className="description">{description}</p>
    </article>
  );
}
