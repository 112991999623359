import React from 'react';

import './WatchMoneySection.scss';

import wym from 'Home/assets/images/WatchMoneySection/watch-your-money.png';
import SectionHeader from '../SectionHeader/SectionHeader';

export default function WatchMoneySection() {
  return (
    <div className="watch-your-money-section">
      <div className="content">
        <SectionHeader text="Watch your money go further with Careseekers" />
        <div className="description">
          <p>
            <span className="topic-title">Pricing is transparent</span>
            Care and support workers set their hourly rates, which are clearly
            stated on their profile. Most workers are open to working within
            your budget too.
          </p>
          <p>
            <span className="topic-title">Use your government funding</span>
            We are a registered NDIS provider.
            We also have partnerships in place with aged and disability providers.
            We work seamlessly with them to manage and pay for your care while
            still giving you choice of how your care is delivered.
          </p>
          <p>
            <span className="topic-title">Pay online</span>
            Our online payment system is secure and easy to use.
          </p>
        </div>
      </div>
      <div className="img-container">
        <img
          src={wym}
          alt="Graph comparing pricing and fees between Careseekers and other organisations"
          width="574"
          height="504"
        />
      </div>
    </div>
  );
}
