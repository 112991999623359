import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Slide, Fade } from 'react-awesome-reveal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import HeaderNav from 'Core/components/HeaderNav';
import Footer from 'Core/components/Footer';
import LoginOptions from 'Auth/components/LoginOptions';
import LoginForm from 'Auth/components/LoginForm';
import VerifyEmail from 'Auth/containers/VerifyEmail';
import { loginPage } from 'metadata';
import { login, redirectUser } from 'Auth/services';

export default function Login(props) {
  const dispatch = useDispatch();
  const {
    isLoggingIn,
    isLoggedIn,
    currentUser,
    loginError,
    isUnverifiedAccount,
  } = useSelector((store) => store.AuthReducer);
  const [showLogin, setShowLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authMessage, setAuthMessage] = useState(null);
  const location = useLocation();

  const onButtonClicked = () => {
    setShowLogin(true);
  };

  const onLoginToEngageClicked = () => {
    window.location = process.env.REACT_APP_CS_OAUTH_URL;
  };

  const onLogin = (userEmail, userPass) => {
    setEmail(userEmail);
    setPassword(userPass);
    setAuthMessage(null);
    dispatch(login(userEmail, userPass));
  };

  const onVerified = () => {
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (isLoggedIn) {
      // eslint-disable-next-line react/destructuring-assignment
      if (props.location && props.location.state) {
        // redirect to a preset state variable
      // eslint-disable-next-line react/destructuring-assignment
        return props.history.push({
          // eslint-disable-next-line react/destructuring-assignment
          pathname: props.location.state.redirectTo || '/',
          // eslint-disable-next-line react/destructuring-assignment
          search: props.location.search || '',
        });
      }

      // eslint-disable-next-line react/destructuring-assignment
      props.history.push({
        pathname: redirectUser(currentUser.role),
        // eslint-disable-next-line react/destructuring-assignment
        search: props.location.search || '',
      });
    }
  // eslint-disable-next-line react/destructuring-assignment
  }, [isLoggedIn, currentUser, props.location, props.history]);

  useEffect(() => {
    if (location.state) {
      if (location.state.showLogin !== undefined) {
        setShowLogin(location.state.showLogin);
      }

      if (location.state.message) {
        setAuthMessage(location.state.message);
      }
    }
  }, [location]);

  return (
    <div className="login" style={{ overflow: 'hidden' }}>
      <Helmet>
        <meta name="description" content={loginPage.description} />
        <meta name="og:title" content={loginPage.title} />
        <meta name="og:description" content={loginPage.description} />
        <title>{loginPage.title}</title>
      </Helmet>

      <HeaderNav />

      <main>
        <Container fluid>
          <Row>
            <Col xs={{ span: 12 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
              {!showLogin && (
                <Slide direction="left" reverse={showLogin} triggerOnce>
                  <Fade reverse={showLogin}>
                    <LoginOptions onButtonClicked={onButtonClicked} onLoginToEngageClicked={onLoginToEngageClicked} />
                  </Fade>
                </Slide>
              )}

              {showLogin && (
                <Slide direction="right" reverse={!showLogin} triggerOnce>
                  <Fade reverse={!showLogin}>
                    <LoginForm
                      isLoggingIn={isLoggingIn}
                      loginError={loginError}
                      authError={authMessage}
                      onBack={() => setShowLogin(false)}
                      onSubmit={onLogin}
                    />
                  </Fade>
                </Slide>
              )}
            </Col>
          </Row>
        </Container>

        <Modal show={isUnverifiedAccount} backdrop="static">
          <Modal.Header>
            <Modal.Title>Verify Account</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <VerifyEmail email={email} onVerified={onVerified} />
          </Modal.Body>
        </Modal>
      </main>

      <Footer />
    </div>
  );
}
