import React from 'react';
import { Link } from 'react-router-dom';
import LoginOption from 'Auth/components/LoginOption';
import csLogo from 'assets/img/logo/logo_main_lg.png';
import scDashLogo from 'assets/img/logo/cs-support.png';
import csEngageLogo from 'assets/img/logo/cs-engage.jpg';
import './LoginOptions.scss';

export default function LoginOptions({ onButtonClicked, onLoginToEngageClicked }) {
  return (
    <div className="login-options">
      <div className="options">
        <LoginOption
          bgImage={csLogo}
          title="Login to Careseekers"
          text="Care workers and care seekers sign in here"
          className="cs-logo"
          onClick={onButtonClicked}
        />

        <LoginOption
          bgImage={scDashLogo}
          title="Login to Careseekers"
          text="Support Coordinators sign in here"
          onClick={onButtonClicked}
        />

        <LoginOption
          bgImage={csEngageLogo}
          title="Login to CS Engage"
          text="Providers sign in here"
          onClick={onLoginToEngageClicked}
        />
      </div>

      <div className="cta text-end">
        <Link to="/providers">Become a partner provider</Link>
      </div>
    </div>
  );
}
