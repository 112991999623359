import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import LoginPage from 'Auth/pages/Login';

const AsyncForgotPasswordPage = lazy(() => import('Auth/containers/ForgotPassword'));
const AsyncResetPasswordPage = lazy(() => import('Auth/containers/ResetPassword'));

export default [
  <Route path="/login" exact component={LoginPage} />,
  <Route path="/forgot-password" exact component={AsyncForgotPasswordPage} />,
  <Route path="/resetPassword" exact component={AsyncResetPasswordPage} />,
];
