const initialState = {
  messages: null,
  isLoading: false,
  isSending: false,
  error: null,
};

export default function MessageStore(state = initialState, action) {
  switch (action.type) {
    case 'SET_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      };
    case 'APPEND_MESSAGES':
      return {
        ...state,
        messages: state.messages.concat(action.payload),
      };
    case 'DELETE_MESSAGE':
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.payload),
      };
    case 'MESSAGES_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'MESSAGE_SENDING':
      return {
        ...state,
        isSending: action.payload,
      };
    case 'MESSAGE_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'RESET_MESSAGE_ERROR':
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
