import React from 'react';
import './WorkerClientImagePair.scss';

export default function WorkerClientImagePair({ client, worker, clientFallback, workerFallback, width, height }) {
  return (
    <div className="worker-client-pair">
      <div>
        <picture>
          <source srcSet={client} type="image/webp" />
          <source srcSet={clientFallback} type="image/png" />
          <img src={clientFallback} alt="" loading="lazy" width={width || '600'} height={height || '604'} />
        </picture>
      </div>

      <div>
        <picture>
          <source srcSet={worker} type="image/webp" />
          <source srcSet={workerFallback} type="image/png" />
          <img src={workerFallback} alt="" loading="lazy" width={width || '600'} height={height || '604'} />
        </picture>
      </div>
    </div>
  );
}
