import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const MyCalendarPage = lazy(() => import('Booking/pages/MyCalendar'));
const MyBookingsPage = lazy(() => import('Booking/pages/MyBookings'));

export default [
  <GuardedRoute allowedRoles={['CS', 'CR']} path="/my-calendar" exact>
    <MyCalendarPage />
  </GuardedRoute>,
  <GuardedRoute allowedRoles={['CS', 'CR']} path="/my-bookings" exact>
    <MyBookingsPage />
  </GuardedRoute>,
];
