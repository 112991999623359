export const careFor = [
  {
    label: 'Myself',
    value: 'Me',
  },

  {
    label: 'Partner/Spouse',
    value: 'Partner/Spouse',
  },

  {
    label: 'Immediate Family',
    value: 'Immediate Family',
  },

  {
    label: 'Extended Family',
    value: 'Extended Family',
  },

  {
    label: 'Friend/Neighbour',
    value: 'Friend',
  },

  {
    label: 'Client',
    value: 'Client',
  },
];

export const searcherRoles = [
  {
    label: 'I am a Case Worker',
    value: 'CaseWorker',
  },

  {
    label: 'I am a Support Coordinator looking for workers for my participants',
    value: 'Support Coordinator',
  },

  {
    label: 'I am a Provider looking for workers for my clients',
    value: 'Provider',
  },

  {
    label: 'I am a Social Worker or discharge planner looking for workers for my discharged patients',
    value: 'Social Worker',
  },

  {
    label: 'Other',
    value: 'Other',
  },
];

export const dayAvailability = [
  { dayAvailability: 'monday', morning: false, afternoon: false, evening: false },
  { dayAvailability: 'tuesday', morning: false, afternoon: false, evening: false },
  { dayAvailability: 'wednesday', morning: false, afternoon: false, evening: false },
  { dayAvailability: 'thursday', morning: false, afternoon: false, evening: false },
  { dayAvailability: 'friday', morning: false, afternoon: false, evening: false },
  { dayAvailability: 'saturday', morning: false, afternoon: false, evening: false },
  { dayAvailability: 'sunday', morning: false, afternoon: false, evening: false },
];

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const getSearcherRole = (target) => searcherRoles.find((r) => r.value === target);

export const getCareForValue = (target) => {
  const role = getSearcherRole(target);

  if (role) {
    return 'Client';
  }

  return careFor.find((c) => c.value === target).value;
};

export const getAvailability = (availability) => {
  const daysRequired = availability.filter((day) => day.morning || day.afternoon || day.evening);
  const av = daysRequired.map((day) => {
    let value = `${day.dayAvailability} `;
    const times = [];

    if (day.morning) {
      times.push('mornings');
    }

    if (day.afternoon) {
      times.push('afternoons');
    }

    if (day.evening) {
      times.push('evenings');
    }

    value += `${times.join(', ')}`;

    return value;
  });

  return av;
};
