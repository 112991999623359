import React from 'react';
import LoadingRipple from 'Core/components/LoadingRipple';
import './SuspenseFallback.scss';

export default function SuspenseFallback() {
  return (
    <div className="suspense">
      <div className="loading">
        <LoadingRipple message="One moment please..." />
      </div>
    </div>
  );
}
