import { client as axios } from 'store';
import { getSkillType } from 'Core/services/additional-skills';

const SEARCH_LIMIT = 25;

export const searchWorkers = (params, start = 0, limit = SEARCH_LIMIT) => async (dispatch) => {
  let queryParams = new URLSearchParams();

  if (params) {
    queryParams = params;

    queryParams.set('locationType', 'ANY');
    queryParams.set('sortType', params.get('sortType') || 'lastActive');
    queryParams.set('start', start || 0);
    queryParams.set('limit', limit);
  }

  dispatch({
    type: 'CARER_SEARCH_IN_PROGRESS',
    payload: true,
  });

  dispatch({
    type: 'CARER_SEARCH_ERROR_RESET',
  });

  try {
    const { data } = await axios({
      method: 'GET',
      url: '/search',
      params: queryParams,
    });

    if (start === 0) {
      dispatch({
        type: 'RESET_CARER_RESULTS',
      });
    }

    dispatch({
      type: 'APPEND_CARER_RESULTS',
      payload: data,
    });

    if (data.length < SEARCH_LIMIT) {
      dispatch({
        type: 'CARER_SEARCH_OVER',
        payload: true,
      });
    } else {
      dispatch({
        type: 'CARER_SEARCH_OVER',
        payload: false,
      });
    }
  } catch (e) {
    dispatch({
      type: 'CARER_SEARCH_ERROR',
      payload: {
        type: 'danger',
        title: 'We\'re so sorry!',
        message: 'Our servers are experiencing some issues at the moment and we could '
          + 'not complete your search query. Please retry in a bit. We regret the inconvenience.',
      },
    });
  } finally {
    dispatch({
      type: 'CARER_SEARCH_IN_PROGRESS',
      payload: false,
    });
  }
};

export const getRecentWorkers = (limit = 3) => async (dispatch) => {
  dispatch({
    type: 'CARER_SEARCH_LOADING',
    payload: true,
  });

  dispatch({
    type: 'CARER_SEARCH_ERROR_RESET',
  });

  try {
    const { data } = await axios({
      method: 'GET',
      url: '/search/recent',
      params: {
        totalResult: limit || 3,
      },
    });

    if (data.length < 2) {
      const response = await axios({
        method: 'GET',
        url: '/search',
        params: {
          start: 0,
          limit: 3,
          sortType: 'newest',
          locationType: 'ANY',
        },
      });

      dispatch({
        type: 'CARER_SEARCH_RANDOM_WORKERS',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'CARER_SEARCH_RANDOM_WORKERS',
        payload: data,
      });
    }
  } catch (e) {
    dispatch({
      type: 'CARER_SEARCH_ERROR',
      payload: {
        title: 'We\'re so sorry!',
        message: 'We\'re having trouble finding recent workers...',
        type: 'danger',
      },
    });
  } finally {
    dispatch({
      type: 'CARER_SEARCH_LOADING',
      payload: false,
    });
  }
};

export const getCarerById = async (id) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `/carer/${id}`,
      headers: {
        Accept: 'application/json',
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getProfile = (id) => async (dispatch) => {
  dispatch({ type: 'PROFILE_LOADING', payload: true });
  dispatch({ type: 'PROFILE_ERROR', payload: null });

  try {
    const data = await getCarerById(id);

    dispatch({ type: 'CARER_PROFILE', payload: data });
    dispatch({ type: 'CARER_PROFILE_UPDATE_PAYLOAD', payload: data });
  } catch (e) {
    dispatch({
      type: 'PROFILE_ERROR',
      payload: {
        message: 'We could not fetch your care worker profile at this time. Please retry in a bit, we '
         + 'regret the inconvenience',
        type: 'danger',
      },
    });
  } finally {
    dispatch({ type: 'PROFILE_LOADING', payload: false });
  }
};

export const getMyCarers = (userId, contractsOnly = false) => async (dispatch) => {
  dispatch({ type: 'MY_CARERS_LOADING', payload: true });
  dispatch({ type: 'CARER_SEARCH_ERROR_RESET' });

  try {
    const { data } = await axios({
      method: 'GET',
      url: `/user/${userId}/my-carers`,
      params: {
        withContractsOnly: contractsOnly || false,
      },
    });

    dispatch({ type: 'MY_CARERS_DATA', payload: data || [] });
  } catch (e) {
    dispatch({
      type: 'CARER_SEARCH_ERROR',
      payload: {
        title: "We're so sorry!",
        message: 'Your care workers could not be fetched. Please retry in a bit, we regret the inconvenience.',
        type: 'danger',
      },
    });
  } finally {
    dispatch({ type: 'MY_CARERS_LOADING', payload: false });
  }
};

export const getParamsForCarerSearch = (query) => {
  const params = new URLSearchParams();

  if (query.location) {
    params.set('location', query.location);
  }

  if (query.skillType.length) {
    query.skillType.forEach((skill) => {
      params.append('skillType', skill.value);
    });
  }

  if (query.gender) {
    params.set('gender', query.gender.toUpperCase());
  }

  if (query.languages.length) {
    query.languages.forEach((language) => {
      params.append('carerLanguage', language.code);
    });
  }

  if (query.keyword) {
    params.set('keyword', query.keyword);
  }

  if (query.ndisScreening) {
    params.set('hasVerifiedNDISScreening', 'true');
  }

  if (query.driving) {
    params.set('driving', 'true');
  }

  if (query.personalCare === 'true') {
    params.set('personalCare', 'true');
  }

  if (query.qualifications.length) {
    query.qualifications.forEach((qual) => {
      params.append('qualifications', qual);
    });
  }

  if (query.newWorkersOnly) {
    params.set('newWorkersOnly', 'true');
  }

  if (query.activeWorkersOnly) {
    params.set('activeWorkersOnly', 'true');
  }

  if (query.sortType) {
    params.set('sortType', query.sortType);
  }

  return params;
};

/**
 * Get query prameters based on job post requirements
 * in order to perform a care worker search
 *
 * @param {Object} jobPost - The job post data
 * @param {boolean} relaxRules - Specifies whether the filters should be strict or relaxed (to get more results)
 * @returns {Object}
 */
export const getSearchParams = (jobPost, relaxRules = false) => {
  const params = new URLSearchParams();

  params.set('location', `${jobPost.suburb}, ${jobPost.state}`);
  params.set('locationType', jobPost.locationType);

  if (jobPost.carerGender) {
    params.set('gender', jobPost.carerGender);
  }

  params.set('sortType', 'lastActive');

  const availability = jobPost.jobDetailsWeeklyAvailability.filter((av) => av.morning || av.afternoon || av.evening)
    .map((av) => av.dayAvailability);

  availability.forEach((av) => {
    params.append('availability', av.substring(0, 3));
  });

  params.set('skillType', getSkillType(jobPost.additionalSkills.map((sk) => sk.code)));

  if (!relaxRules) {
    const languages = jobPost.jobLanguages.map((lang) => lang.code);

    languages.forEach((language) => {
      params.append('carerLanguage', language);
    });
  }

  return params;
};

export const getDistanceInKm = (distance) => Math.round((distance / 1000) * 100) / 100;

export const getWeekdayRate = (rateList) => {
  let weeklyRate = 0;

  if (!rateList) {
    return null;
  }

  const rates = rateList.split(',');

  rates.forEach((rate) => {
    if (rate.toLowerCase().indexOf('weekday') > -1) {
      [, weeklyRate] = rate.split('-');
    }
  });

  return weeklyRate;
};

export const getAvailability = (days) => {
  // ignore 0th index when iterating
  const allDays = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const daysAvailable = [];
  const workerAvailability = days.split(',').sort();

  if (days && days.length) {
    workerAvailability.forEach((day) => daysAvailable.push(allDays[parseInt(day, 10)]));
  }

  return daysAvailable;
};

export const getBriefDescription = (description, limit = 350) => {
  let briefDescription = '';

  if (description) {
    briefDescription = description.slice(0, limit);

    if (description.length > limit) {
      briefDescription += '...';
    }
  }

  return briefDescription;
};
