import { combineReducers } from 'redux';
import CoreStore from 'Core/reducers';
import AuthReducer from 'Auth/reducers';
import CareSeekerReducer from 'CareSeeker/reducers';
import JobReducer from 'Job/reducers';
import CarerSearchReducer from 'CarerSearch/reducers';
import CarerReducer from 'Carer/reducers';
import CarerReviewStore from 'CarerReview/reducers';
import InvoiceStore from 'Invoice/reducers';
import TimesheetStore from 'Timesheet/reducers';
import ShiftNoteStore from 'ShiftNote/reducers';
import ContractStore from 'Contract/reducers';
import MessageStore from 'Message/reducers';
import ProviderStore from 'CareSeeker/reducers/provider';
import BookingStore from 'Booking/reducers';
import FeedbackStore from 'Feedback/reducers';
import HomeStore from 'Home/reducers';
import StripeStore from 'Stripe/reducers';
import SCStore from 'SupportCoordinator/reducers';
import ShortlistStore from 'Shortlist/reducers';

const appReducer = combineReducers({
  CoreStore,
  AuthReducer,
  CareSeekerReducer,
  JobReducer,
  CarerSearchReducer,
  CarerReviewStore,
  CarerReducer,
  InvoiceStore,
  TimesheetStore,
  ShiftNoteStore,
  ContractStore,
  MessageStore,
  ProviderStore,
  BookingStore,
  FeedbackStore,
  HomeStore,
  StripeStore,
  SCStore,
  ShortlistStore,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
