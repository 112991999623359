import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingRipple from 'Core/components/LoadingRipple';
import SectionHeader from 'Home/components/SectionHeader/SectionHeader';
import WorkerCard from 'Home/components/WorkerCard';
import { getRecentWorkers } from 'CarerSearch/services';
import './WorkerSection.scss';

export default function WorkerSection({ header }) {
  const { isSearching, randomWorkers, alertMessage } = useSelector((store) => store.CarerSearchReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRecentWorkers(3));
  }, [dispatch]);

  return (
    <div className="worker-section">
      <SectionHeader text={header || 'Meet our care and support workers'} />

      {isSearching && randomWorkers.length === 0 && (
        <div className="my-4 py-4 text-center">
          <LoadingRipple message="Getting recent workers..." />
        </div>
      )}

      {alertMessage && (
        <Alert variant={alertMessage.type} className="alert-message mt-3">
          <Alert.Heading>{alertMessage.title}</Alert.Heading>

          <p className="mb-0">{alertMessage.message}</p>
        </Alert>
      )}

      {randomWorkers.length > 0 && (
        <div className="card-list">
          <Row>
            {randomWorkers.map((worker) => (
              <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 0 }} className="d-grid" key={worker.carerId}>
                <WorkerCard
                  img={worker.avatar}
                  alt={worker.firsName}
                  name={worker.firstName}
                  suburb={worker.suburb}
                  state={worker.state}
                  blurb={worker.description}
                  carerId={worker.carerId}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}
