import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const Signup = lazy(() => import('Carer/pages/Signup'));
const WelcomePage = lazy(() => import('Carer/pages/Welcome'));
const WorkerProfile = lazy(() => import('Carer/WorkerProfile/pages/WorkerProfile'));
const CarerDashboard = lazy(() => import('Carer/containers/Dashboard'));
const UpdateProfile = lazy(() => import('Carer/UpdateWorkerProfile/pages/UpdateProfile'));
const TaxAndSuper = lazy(() => import('Carer/pages/TaxAndSuper'));
const LearningHub = lazy(() => import('Carer/pages/LearningHub'));

export default [
  <Route path="/careworker/register" exact component={Signup} />,

  <GuardedRoute allowedRoles={['CR']} path="/care-worker/welcome" exact>
    <WelcomePage />
  </GuardedRoute>,

  <Route path="/carer/profile/:carerId" exact component={WorkerProfile} />,

  <GuardedRoute allowedRoles={['CR']} path="/carer/profile" exact>
    <WorkerProfile />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/care-worker/dashboard" exact>
    <CarerDashboard />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/care-worker/update-profile" exact>
    <UpdateProfile />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/tax-and-super" exact>
    <TaxAndSuper />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/learning-hub" exact>
    <LearningHub />
  </GuardedRoute>,
];
