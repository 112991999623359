import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import OffCanvas from 'react-bootstrap/Offcanvas';
import { IoPersonCircleOutline } from 'react-icons/io5';
import UserLinks from 'Core/components/UserLinks';
import BusinessHub from 'Carer/components/BusinessHub';
import SidebarNav from 'Core/components/SidebarNav';
import SCSidebar from 'SupportCoordinator/components/SCSidebar';
import DashboardMenuButton from 'SupportCoordinator/components/DashboardMenuButton';
import { aboutLinks, howItWorks, resources, supportLinks } from 'Core/services/sidebar-menu';
import './HeaderNav.scss';
import mainLogoWebp from 'assets/img/logo/logo-sm.webp';
import mainLogo from 'assets/img/logo/logo-sm.png';

export default function HeaderNav() {
  const { currentUser, isLoggedIn, isSupportCoordinator } = useSelector((store) => store.AuthReducer);
  const { isBusinessHubOpen } = useSelector((store) => store.CarerReducer);
  const { isSidebarOpen } = useSelector((store) => store.CoreStore);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleBusinessHub = () => {
    dispatch({
      type: 'TOGGLE_BUSINESS_HUB_MENU',
      payload: !isBusinessHubOpen,
    });
  };

  const toggleSidebar = () => {
    dispatch({
      type: 'SIDEBAR_TOGGLE',
      payload: !isSidebarOpen,
    });
  };

  const getSupportLink = () => {
    if (currentUser) {
      const supportLink = supportLinks.find((link) => link.id === currentUser.role);

      if (supportLink) {
        return supportLink.href;
      }

      return null;
    }

    return null;
  };

  useEffect(() => {
    const listener = () => history.listen(() => {
      if (isBusinessHubOpen) {
        dispatch({
          type: 'TOGGLE_BUSINESS_HUB_MENU',
          payload: false,
        });
      }
    });

    return () => {
      listener();
    };
  }, [history, isBusinessHubOpen, dispatch]);

  return (
    <>
      <header id="main-header">
        <div className="cs-navbar">
          <Navbar bg="light" variant="light" className="nav-bottom">
            <Container fluid className="position-relative">
              <Link className="navbar-brand" to="/">
                <picture id="logo-lg">
                  <source srcSet={mainLogoWebp} type="image/webp" />
                  <source srcSet={mainLogo} type="image/png" />
                  <img src={mainLogo} alt="Careseekers logo" width="74" height="36" />
                </picture>
              </Link>

              <div className="mobile-link main-menu">
                <Button size="sm" onClick={toggleSidebar}>
                  Menu
                </Button>
              </div>

              {isLoggedIn && currentUser.role === 'CR' && (
                <div className="mobile-link dash-menu">
                  <Button size="sm" variant="link" aria-label="My Account" onClick={toggleBusinessHub}>
                    <IoPersonCircleOutline size={24} />
                  </Button>
                </div>
              )}

              {isSupportCoordinator && (
                <div className="mobile-link dash-menu">
                  <DashboardMenuButton />
                </div>
              )}

              <Nav className="cs-links mr-auto align-items-center">
                <Dropdown>
                  <Dropdown.Toggle variant="cta-link">
                    About us
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {aboutLinks.map((link) => (
                      <Link to={link.to} className="dropdown-item" key={link.id}>{link.label}</Link>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle variant="cta-link">
                    How it works
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {howItWorks.map((link) => (
                      <Link to={link.to} className="dropdown-item" key={link.id}>{link.label}</Link>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                  <Dropdown.Toggle variant="cta-link">
                    Safety & resources
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {resources.map((link) => (
                      <Link to={link.to} className="dropdown-item" key={link.id}>{link.label}</Link>
                    ))}

                    {currentUser !== null && getSupportLink() !== null && (
                      <a href={getSupportLink()} className="dropdown-item">
                        Help Centre
                      </a>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <Nav.Link href="https://blog.careseekers.com.au">Blog</Nav.Link>

                {(!isLoggedIn || (currentUser !== null && currentUser.role === 'CS')) && (
                  <>
                    <Link to="/careseeker/post-a-job" className="nav-link cta-links cta-primary">Post a job</Link>

                    <Link to="/care-workers/search" className="nav-link cta-links cta-secondary">Find workers</Link>
                  </>
                )}

                {currentUser !== null && currentUser.role === 'CR' && (
                  <Link to="/jobs/search" className="nav-link cta-links cta-primary">Find jobs</Link>
                )}

                {currentUser !== null && currentUser.role === 'ENGAGEADMIN' && (
                  <a href={process.env.REACT_APP_CS_OAUTH_URL} className="nav-link cta-links cta-primary">
                    Engage Platform
                  </a>
                )}

                {!isLoggedIn && (
                  <Link to="/login" className="nav-link">Login</Link>
                )}

                {isLoggedIn && (
                  <UserLinks currentUser={currentUser} isSupportCoordinator={isSupportCoordinator} />
                )}
              </Nav>
            </Container>
          </Navbar>
        </div>
      </header>

      <OffCanvas show={isBusinessHubOpen} onHide={toggleBusinessHub} placement="end">
        <OffCanvas.Header closeButton />

        <OffCanvas.Body>
          <aside>
            <BusinessHub />
          </aside>
        </OffCanvas.Body>
      </OffCanvas>

      <OffCanvas show={isSidebarOpen} onHide={toggleSidebar} placement="start">
        <OffCanvas.Header closeButton />

        <OffCanvas.Body>
          <aside>
            <SidebarNav />
          </aside>
        </OffCanvas.Body>
      </OffCanvas>

      {isSupportCoordinator && (
        <SCSidebar />
      )}
    </>
  );
}
