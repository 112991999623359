const initialState = {
  posts: [],
  loadingState: 'initial',
};

export default function HomeStore(state = initialState, action) {
  switch (action.type) {
    case 'INSTA_POSTS_REQUEST':
      return {
        ...state,
        loadingState: 'loading',
      };
    case 'INSTA_POSTS_SUCCESS':
      return {
        ...state,
        posts: action.payload,
        loadingState: 'loaded',
      };
    case 'INSTA_POSTS_FAILURE':
      return {
        ...state,
        loadingState: 'error',
        posts: action.payload,
      };
    default:
      return state;
  }
}
