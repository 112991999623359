import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const DownloadPDF = lazy(() => import('Core/pages/DownloadPDF'));

export default [
  <GuardedRoute path="/contract/download" allowedRoles={['CS', 'CR']} exact>
    <DownloadPDF />
  </GuardedRoute>,
];
