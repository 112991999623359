import React from 'react';
import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin } from 'react-icons/ai';
import { FiTwitter } from 'react-icons/fi';
import InstagramFeed from 'Home/containers/InstagramFeed';
import SectionHeader from 'Home/components/SectionHeader/SectionHeader';
import GetStartedOptions from 'Home/components/GetStartedOptions';
import './CSCommunitySection.scss';

export default function CSCommunitySection() {
  return (
    <div className="cs-community-section">
      <SectionHeader text="Hear from our Careseekers community" />

      <InstagramFeed />

      <div>
        <SectionHeader text="Connect with Careseekers" />

        <div className="social-icon-container">
          <a href="https://www.facebook.com/careseekersforfamilies/" target="_blank" aria-label="Connect with Careseekers on Facebook" rel="noreferrer noopener">
            <AiOutlineFacebook />
          </a>

          <a href="https://www.instagram.com/careseekers_aus/" target="_blank" aria-label="Connect with Careseekers on Instagram" rel="noreferrer noopener">
            <AiOutlineInstagram />
          </a>

          <a href="https://www.linkedin.com/company/careseekers" target="_blank" aria-label="Connect with Careseekers on LinkedIn" rel="noreferrer noopener">
            <AiOutlineLinkedin />
          </a>

          <a href="https://twitter.com/Careseekers_Aus" target="_blank" aria-label="Connect with Careseekers on Twitter" rel="noreferrer noopener">
            <FiTwitter />
          </a>
        </div>
      </div>

      <div className="built-for-you-container">
        <SectionHeader text="Find out how the CS platform is built for you" />

        <div className="select-container">
          <GetStartedOptions />
        </div>
      </div>
    </div>
  );
}
