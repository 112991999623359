import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const FeedbackComplaintsPage = lazy(() => import('Feedback/pages/FeedbackComplaintsPage'));
const FeedbackPage = lazy(() => import('Feedback/pages/FeedbackPage'));
const MakeAComplaintPage = lazy(() => import('Feedback/pages/MakeAComplaintPage'));
const ReportIncidentPage = lazy(() => import('Feedback/pages/ReportIncidentPage'));
const WorkHealthSafetyPage = lazy(() => import('Feedback/pages/WorkHealthSafetyPage'));

export default [
  <Route path="/feedback-complaints-incident-reports" exact component={FeedbackComplaintsPage} />,
  <Route path="/feedback" exact component={FeedbackPage} />,
  <Route path="/make-a-complaint" exact component={MakeAComplaintPage} />,
  <Route path="/report-incident" exact component={ReportIncidentPage} />,
  <Route path="/work-health-and-safety" exact component={WorkHealthSafetyPage} />,
];
