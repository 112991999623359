import { client as axios } from 'store';
import { GTMTrackEvent, GTMUserTrack } from 'Core/services/event-tracking';

export const redirectUser = (role) => {
  let path = '/';

  switch (role) {
    case 'CS':
      path = '/dashboard';
      break;
    case 'CR':
      path = '/care-worker/dashboard';
      break;
    case 'ADMIN':
      // path = '/admin/search/care-workers';
      window.location = `${process.env.REACT_APP_CS_DOMAIN}admins`;
      break;
    case 'SUPPORT':
      path = '/support-coordinator/dashboard';
      break;
    default:
      path = '/';
  }

  return path;
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: 'SET_LOGGING_IN', payload: true });
    dispatch({ type: 'SET_LOGIN_ERROR', payload: null });

    const { data } = await axios({
      method: 'POST',
      url: '/signin',
      data: {
        email,
        password,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const currentUser = {
      firstName: data.firstName,
      lastName: data.lastName,
      name: data.name,
      email: data.email,
      id: data.id,
      authToken: data.authToken,
      role: data.role,
      superUserLogin: data.superUserLogin,
      freshchatId: data.freshchatId,
    };

    Object.entries(currentUser).forEach((entry) => {
      localStorage.setItem(entry[0], entry[1]);
    });

    dispatch({ type: 'SET_TOKEN', payload: data.authToken });
    dispatch({ type: 'SET_CURRENT_USER', payload: currentUser });
    dispatch({ type: 'SET_LOGGED_IN', payload: true });

    if (currentUser.role === 'SUPPORT') {
      dispatch({ type: 'SET_SUPPORT_COORDINATOR', payload: true });
    }

    dispatch({ type: 'SET_LOGGING_IN', payload: false });
    GTMTrackEvent('Log-In', { category: 'Submit', label: `User role: ${currentUser.role}` });
    GTMUserTrack(data.id);
  } catch (e) {
    let message = '';

    if (e.response.status === 401 && e.response.data.message === 'EVF') {
      message = 'You must verify your email address before you can login and start using Careseekers. '
        + 'Please check your email for instructions on how to verify your email address.';

      dispatch({ type: 'SET_UNVERIFIED_ACCOUNT', payload: true });
    } else if (e.response.status === 403) {
      message = 'Your account has been deactivated by an administrator. If you feel this is '
        + 'a mistake, or if you have any questions, please send an email to info@careseekers.com.au.';
    } else {
      message = 'Invalid email address or password';
    }

    dispatch({ type: 'SET_LOGIN_ERROR', payload: { message, type: 'danger' } });
    dispatch({ type: 'SET_LOGGING_IN', payload: false });
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: 'LOGOUT' });
  GTMTrackEvent('Logged-Out', { category: 'Submit', label: `User role: ${localStorage.getItem('role')}` });
  GTMUserTrack(undefined);

  localStorage.removeItem('authToken');
  localStorage.removeItem('name');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('freshchatId');
  localStorage.removeItem('id');
  localStorage.removeItem('email');
  localStorage.removeItem('role');
  localStorage.removeItem('superUserLogin');
};

export const setAliasCredentials = (data) => (dispatch) => {
  dispatch({
    type: 'SET_CURRENT_USER',
    payload: {
      aliasToken: data.authToken,
      name: data.name,
      firstName: data.firstName,
      id: data.id,
      email: data.email,
      role: data.role,
    },
  });

  dispatch({ type: 'SET_SUPPORT_COORDINATOR', payload: true });

  localStorage.setItem('alias_aliasToken', data.authToken);
  localStorage.setItem('alias_name', data.name);
  localStorage.setItem('alias_firstName', data.firstName);
  localStorage.setItem('alias_userId', data.id);
  localStorage.setItem('alias_email', data.email);
  localStorage.setItem('alias_role', data.role);

  dispatch({ type: 'SET_TOKEN', payload: data.authToken });
};

export const clearAliasCredentials = () => (dispatch) => {
  dispatch({
    type: 'SET_CURRENT_USER',
    payload: {
      aliasToken: undefined,
      name: localStorage.getItem('name'),
      firstName: localStorage.getItem('firstName'),
      id: localStorage.getItem('id'),
      email: localStorage.getItem('email'),
      role: localStorage.getItem('role'),
      authToken: localStorage.getItem('authToken'),
    },
  });

  dispatch({ type: 'SET_TOKEN', payload: localStorage.getItem('authToken') });
  dispatch({ type: 'SET_SUPPORT_COORDINATOR', payload: true });

  localStorage.removeItem('alias_aliasToken');
  localStorage.removeItem('alias_name');
  localStorage.removeItem('alias_firstName');
  localStorage.removeItem('alias_userId');
  localStorage.removeItem('alias_email');
  localStorage.removeItem('alias_role');
};

export const forgotPassword = async (email) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: '/auth/forgot',
      data: { email },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resetPassword = async (email, password, verificationToken) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: '/auth/reset',
      data: { email, password, verificationToken },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const verifyEmail = async (email, emailVerificationCode) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `/auth/verify/${email}/${emailVerificationCode}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const resendVerificationEmail = async (email) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `/auth/resetEmailVerification/${email}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
