const initialState = {
  isLoading: false,
  alertMessage: null,
};

export default function CarerReviewStore(state = initialState, action) {
  switch (action.type) {
    case 'REVIEW_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'REVIEW_ALERT':
      return {
        ...state,
        alertMessage: action.payload,
      };
    case 'REVIEW_ALERT_RESET':
      return {
        ...state,
        alertMessage: null,
      };
    default:
      return state;
  }
}
