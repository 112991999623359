import React from 'react';
import { useHistory } from 'react-router-dom';
import './HelpYouCard.scss';

export default function HelpYouCard({ img, alt, text, route }) {
  const history = useHistory();

  const handleCardClick = () => {
    history.push(route);
  };

  const handleKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      history.push(route);
    }
  };

  return (
    <div
      className="help-you-card"
      onClick={handleCardClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      <img src={img} alt={alt} width="180" height="180" loading="lazy" />
      <div className="title">{text}</div>
    </div>
  );
}
