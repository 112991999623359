const initialState = {
  isSearching: false,
  carerResults: [],
  searchComplete: false, // indicates whether there are no more results to return from the API
  randomWorkers: [],
  location: '',
  skillType: [],
  gender: '',
  languages: [],
  keyword: '',
  ndisScreening: null,
  driving: null,
  personalCare: null,
  qualifications: [],
  newWorkersOnly: false,
  activeWorkersOnly: false,
  sortType: 'bestMatch',
  alertMessage: null,
  myCarers: null,
  isLoadingMyCarers: false,
};

export default function CarerSearchReducer(state = initialState, action) {
  switch (action.type) {
    case 'CARER_SEARCH_IN_PROGRESS':
      return {
        ...state,
        isSearching: action.payload,
      };
    case 'APPEND_CARER_RESULTS':
      return {
        ...state,
        carerResults: state.carerResults.concat(action.payload),
      };
    case 'RESET_CARER_RESULTS':
      return {
        ...state,
        carerResults: [],
      };
    case 'CARER_SEARCH_RANDOM_WORKERS':
      return {
        ...state,
        randomWorkers: action.payload,
      };
    case 'CARER_SEARCH_LOCATION':
      // eslint-disable-next-line no-case-declarations
      const parts = action.payload ? action.payload.split(', ') : action.payload;

      // eslint-disable-next-line no-case-declarations
      const location = Array.isArray(parts) ? `${parts[0]}, ${parts[1]}` : parts;

      return {
        ...state,
        location,
      };
    case 'CARER_SEARCH_SET_SKILL_TYPE':
      return {
        ...state,
        skillType: action.payload,
      };
    case 'CARER_SEARCH_ADD_SKILL_TYPE':
      if (state.skillType.indexOf(action.payload) === -1) {
        return {
          ...state,
          skillType: state.skillType.concat(action.payload),
        };
      }

      return {
        ...state,
      };
    case 'CARER_SEARCH_REMOVE_SKILL_TYPE':
      return {
        ...state,
        skillType: state.skillType.filter((st) => st !== action.payload),
      };
    case 'CARER_SEARCH_CLEAR_SKILL_TYPE':
      return {
        ...state,
        skillType: [],
      };
    case 'CARER_SEARCH_GENDER':
      return {
        ...state,
        gender: action.payload,
      };
    case 'CARER_SEARCH_SET_LANGUAGES':
      return {
        ...state,
        languages: action.payload,
      };
    case 'CARER_SEARCH_ADD_LANGUAGE':
      if (state.languages.indexOf(action.payload) === -1) {
        return {
          ...state,
          languages: state.languages.concat(action.payload),
        };
      }

      return {
        ...state,
      };
    case 'CARER_SEARCH_REMOVE_LANGUAGE':
      return {
        ...state,
        languages: state.languages.filter((lang) => lang !== action.payload),
      };
    case 'CARER_SEARCH_CLEAR_LANGUAGES':
      return {
        ...state,
        languages: [],
      };
    case 'CARER_SEARCH_KEYWORDS':
      return {
        ...state,
        keyword: action.payload,
      };
    case 'CARER_SEARCH_NDIS_SCREENING':
      return {
        ...state,
        ndisScreening: action.payload,
      };
    case 'CARER_SEARCH_DRIVING':
      return {
        ...state,
        driving: action.payload,
      };
    case 'CARER_SEARCH_PERSONAL_CARE':
      return {
        ...state,
        personalCare: action.payload,
      };
    case 'CARER_SEARCH_ADD_QUALIFICATION':
      if (state.qualifications.indexOf(action.payload) === -1) {
        return {
          ...state,
          qualifications: state.qualifications.concat(action.payload),
        };
      }

      return {
        ...state,
      };
    case 'CARER_SEARCH_REMOVE_QUALIFICATION':
      return {
        ...state,
        qualifications: state.qualifications.filter((q) => q !== action.payload),
      };
    case 'CARER_SEARCH_NEW_WORKERS':
      return {
        ...state,
        newWorkersOnly: action.payload,
      };
    case 'CARER_SEARCH_ACTIVE_WORKERS':
      return {
        ...state,
        activeWorkersOnly: action.payload,
      };
    case 'CARER_SEARCH_SORT_TYPE':
      return {
        ...state,
        sortType: action.payload,
      };
    case 'CARER_SEARCH_OVER':
      return {
        ...state,
        searchComplete: action.payload,
      };
    case 'CARER_SEARCH_ERROR':
      return {
        ...state,
        alertMessage: action.payload,
      };
    case 'CARER_SEARCH_ERROR_RESET':
      return {
        ...state,
        alertMessage: null,
      };
    case 'MY_CARERS_DATA':
      return {
        ...state,
        myCarers: action.payload,
      };
    case 'MY_CARERS_LOADING':
      return {
        ...state,
        isLoadingMyCarers: action.payload,
      };
    default:
      return state;
  }
}
