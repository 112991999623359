import TagManager from 'react-gtm-module';

// DEPRECATED, will be phased out for GA4 intefration
export const GTMTrackEvent = (action, { event = 'interaction', category, label, value, noninteraction = false }) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      target: category,
      action,
      'target-properties': label,
      value,
      'interaction-type': noninteraction,
    },
  });
};

export const GTMEventTrack = ({ event, parameters }) => {
  TagManager.dataLayer({
    dataLayer: { event, ...parameters },
  });
};

export const GTMPageTrack = (title, path, search) => {
  // Clear out the ecommerce object: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clearing_the_ecommerce_object
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'content-view',
      pageTitle: title,
      path: `${path}${search}`,
      'interaction-type': true, // not an interaction
    },
  });
};

export const GTMEcommerceTrack = (invoiceId, amount, userId, qty = 1) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: invoiceId.toString(),
            revenue: parseFloat(amount),
          },
          products: [{
            id: invoiceId.toString(),
            item_id: invoiceId.toString(),
            name: userId,
            item_name: userId,
            price: parseFloat(amount),
            currency: 'AUD',
            quantity: qty,
          }],

          value: parseFloat(amount),
        },
      },
    },
  });
};

export const GTMUserTrack = (userId) => {
  TagManager.dataLayer({
    dataLayer: {
      user_id: userId,
    },
  });
};

export const fbTrack = (eventName, data) => {
  if (process.env.REACT_APP_ENV === 'production') {
    if (window.fbq) {
      window.fbq('track', eventName, data);
    }
  }
};

export const fbTrackCustom = (eventName, data) => {
  if (process.env.REACT_APP_ENV === 'production') {
    if (window.fbq) {
      window.fbq('trackCustom', eventName, data);
    }
  }
};
