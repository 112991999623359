import React from 'react';
import { Carousel } from 'react-bootstrap';
import WorkerClientImagePair from 'Home/components/WorkerClientImagePair';
import GetStartedCard from 'Home/components/GetStartedCard';
import GetStartedOptions from 'Home/components/GetStartedOptions';
import { matchingPairs } from 'Home/services';
import './GetStartedSection.scss';

export default function GetStartedSection() {
  return (
    <div className="get-started-section">
      <GetStartedCard
        title="The Service Built Around You"
        description="Aged care and disability support on your terms"
      >
        <GetStartedOptions />
      </GetStartedCard>

      <div className="careseekers-matches">
        <Carousel className="bg-corousel" controls={false} indicators={false} interval={4000}>
          {matchingPairs.map((match) => (
            <Carousel.Item key={match.id}>
              <WorkerClientImagePair
                client={match.csMain}
                clientFallback={match.cs}
                worker={match.crMain}
                workerFallback={match.cr}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>

  );
}
