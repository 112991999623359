import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const SCLandingPage = lazy(() => import('SupportCoordinator/pages/SCLandingPage'));
const Signup = lazy(() => import('SupportCoordinator/pages/Signup'));
const SCDashboard = lazy(() => import('SupportCoordinator/pages/SCDashboard'));
const AddClient = lazy(() => import('SupportCoordinator/pages/AddNewClient'));
const RequestLinking = lazy(() => import('SupportCoordinator/pages/RequestLinking'));

export default [
  <Route path="/support-coordinator" exact component={SCLandingPage} />,
  <Route path="/support-coordinator/register" exact component={Signup} />,

  <GuardedRoute allowedRoles={['SUPPORT']} path="/support-coordinator/dash" exact>
    <SCDashboard />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['SUPPORT']} path="/support-coordinator/dashboard" exact>
    <SCDashboard />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['SUPPORT']} path="/support-coordinator/add-client" exact>
    <AddClient />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['SUPPORT']} path="/support-coordinator/request-linking" exact>
    <RequestLinking />
  </GuardedRoute>,
];
