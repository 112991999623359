const initialState = {
  status: null,
  shortlist: null,
  alertMessage: null,
};

export default function ShortlistStore(state = initialState, action) {
  switch (action.type) {
    case 'SHORTLIST_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'SHORTLIST_STATUS_RESET':
      return {
        ...state,
        status: null,
      };
    case 'SHORTLIST_DATA':
      return {
        ...state,
        shortlist: action.payload,
      };
    case 'SHORTLIST_DATA_APPEND':
      // eslint-disable-next-line no-case-declarations
      const list = state.shortlist || [];

      return {
        ...state,
        shortlist: [action.payload].concat(list),
      };
    case 'SHORTLIST_DATA_REMOVE':
      // Remove workers from shortlist whose IDs are in action.payload array
      return {
        ...state,
        shortlist: state.shortlist.filter((worker) => action.payload.indexOf(worker.carerId) === -1),
      };
    case 'SHORTLIST_ALERT':
      return {
        ...state,
        alertMessage: action.payload,
      };
    case 'SHORTLIST_ALERT_RESET':
      return {
        ...state,
        alertMessage: null,
      };
    default:
      return state;
  }
}
