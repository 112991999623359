import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingRipple from 'Core/components/LoadingRipple';
import InstagramCard from 'Home/components/InstagramCard';
import { getPosts } from 'Home/services';
import './InstagramFeed.scss';

export default function InstagramFeed() {
  const { posts, loadingState } = useSelector((store) => store.HomeStore);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadingState === 'initial') {
      dispatch(getPosts());
    }
  }, [dispatch, loadingState]);

  return (
    <div className="instagram-feed">
      {loadingState === 'loading' && (
        <div className="my-4 text-center">
          <LoadingRipple message="Getting Instagram feed..." />
        </div>
      )}

      {loadingState !== 'loading' && (
        <>
          {posts.map((post) => (
            <InstagramCard post={post} isDefault={loadingState === 'error'} key={post.id} />
          ))}
        </>
      )}
    </div>
  );
}
