import React from 'react';
import './LoginOption.scss';

export default function LoginOption({ bgImage, title, text, onClick, className }) {
  return (
    <button
      className={`login-option ${className || ''}`}
      type="button"
      title={title}
      onClick={onClick}
      aria-label={text}
    >
      <img src={bgImage} className="img-fluid" width="341" height="26" alt="" loading="lazy" />
      <span>{text}</span>
    </button>
  );
}
