import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axiosConfig from 'axiosConfig';
import SuspenseFallback from 'Core/components/SuspenseFallback';
import ScrollToTop from 'helpers/ScrollToTop';
import AppInit from 'helpers/AppInit';
import PageTrack from 'helpers/PageTrack';
import Fullstory from 'helpers/Fullstory';
import Routes from 'routes';
import store, { client } from './store';

// const Freshchat = lazy(() => import('helpers/Freshchat'));

axiosConfig({ store, client });

const tagManagerArgs = { gtmId: process.env.REACT_APP_CS_GTM_ID };
const stripePromise = loadStripe(process.env.REACT_APP_CS_STRIPE_KEY);

TagManager.initialize(tagManagerArgs);

function App() {
  useEffect(() => {
    // Load Zendesk Widget script after component mounts
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9bc778da-783d-4af7-9833-ab72a5af5fc5';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup if component unmounts
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <HelmetProvider>
            <AppInit />
            <Router>
              <ScrollToTop />
              <PageTrack />
              <Fullstory />
              <Suspense fallback={<div />}>
                {/* <Freshchat /> */}
              </Suspense>
              <Suspense fallback={<SuspenseFallback />}>
                <Routes />
              </Suspense>
            </Router>
          </HelmetProvider>
        </Elements>
      </Provider>
    </div>
  );
}

export default App;
