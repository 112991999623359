import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const WorkersPage = lazy(() => import('AboutUs/pages/WorkersPage'));
const AboutUsPage = lazy(() => import('AboutUs/pages/AboutUsPage'));
const FoundersPage = lazy(() => import('AboutUs/pages/FoundersPage'));
const PrivacyPolicyPage = lazy(() => import('AboutUs/pages/PrivacyPolicyPage'));
const ContactUsPage = lazy(() => import('AboutUs/pages/ContactUsPage'));
const TermsOfUsePage = lazy(() => import('AboutUs/pages/TermsOfUsePage'));
const ComplaintsPolicyPage = lazy(() => import('AboutUs/pages/ComplaintsPolicyPage'));
const PoliceCheckPolicyPage = lazy(() => import('AboutUs/pages/PoliceCheckPolicyPage'));
const CustomerServiceCharterPage = lazy(() => import('AboutUs/pages/CustomerServiceCharterPage'));
const CodeOfConductPage = lazy(() => import('AboutUs/pages/CodeOfConductPage'));
const ProtectionOfHRPage = lazy(() => import('AboutUs/pages/ProtectionOfHRPage'));
const InsurancePolicyPage = lazy(() => import('AboutUs/pages/InsurancePolicyPage'));
const NDISFundedSupportPage = lazy(() => import('AboutUs/pages/NDISFundedSupportPage'));
const MediaCentrePage = lazy(() => import('AboutUs/pages/MediaCentrePage'));
const Testimonials = lazy(() => import('AboutUs/pages/Testimonials'));

export default [
  <Route path="/about-us" exact component={AboutUsPage} />,
  <Route path="/founders" exact component={FoundersPage} />,
  <Route path="/workers" exact component={WorkersPage} />,
  <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />,
  <Route path="/contact-us" exact component={ContactUsPage} />,
  <Route path="/terms-conditions" exact component={TermsOfUsePage} />,
  <Route path="/complaints-policy" exact component={ComplaintsPolicyPage} />,
  <Route path="/police-check-policy" exact component={PoliceCheckPolicyPage} />,
  <Route path="/customer-service-charter" exact component={CustomerServiceCharterPage} />,
  <Route path="/code-of-conduct" exact component={CodeOfConductPage} />,
  <Route path="/protection-of-human-rights-freedom-from-abuse-and-neglect" exact component={ProtectionOfHRPage} />,
  <Route path="/insurance-policy" exact component={InsurancePolicyPage} />,
  <Route path="/ndis-funded-support" exact component={NDISFundedSupportPage} />,
  <Route path="/media-centre" exact component={MediaCentrePage} />,
  <Route path="/testimonials" exact component={Testimonials} />,

];
