import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { logout } from 'Auth/services';

export default function UserLinks({ currentUser, isSupportCoordinator = false }) {
  const { isBusinessHubOpen } = useSelector((store) => store.CarerReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (e) => {
    dispatch(logout());

    history.push('/');
    e.preventDefault();
  };

  const toggleBusinessHub = () => {
    dispatch({
      type: 'TOGGLE_BUSINESS_HUB_MENU',
      payload: !isBusinessHubOpen,
    });
  };

  const toggleSCDashMenu = () => {
    dispatch({
      type: 'SC_SIDEBAR_TOGGLE',
      payload: true,
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        id="user-menu"
        className="username"
        title={`${currentUser.name}`}
        aria-label="My Account"
      >
        <IoPersonCircleOutline size={24} />
        <span className="d-inline-block ms-2" style={{ fontSize: '0.9rem' }}>{currentUser.firstName}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {currentUser.role === 'CR' && (
          <>
            <Dropdown.Item onClick={toggleBusinessHub}>My Account</Dropdown.Item>

            <Link to="/care-worker/dashboard" className="dropdown-item">My Dashboard</Link>
          </>
        )}

        {currentUser.role === 'CS' && (
          <Link to="/dashboard" className="dropdown-item">My Dashboard</Link>
        )}

        {currentUser.role === 'ADMIN' && (
          // <Link to="/admin/search/care-workers" className="dropdown-item">Admin Dashboard</Link>
          <a href="/admins" className="dropdown-item">Admin Dashboard</a>
        )}

        {isSupportCoordinator && (
          <Dropdown.Item onClick={toggleSCDashMenu}>My SC Dashboard</Dropdown.Item>
        )}

        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
