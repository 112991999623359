import { createStore, applyMiddleware, compose } from 'redux';
import ReduxPromise from 'redux-promise';
import Thunk from 'redux-thunk';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import rootReducer from '../reducers';

const initialState = {};

export const client = axios.create({
  baseURL: process.env.REACT_APP_CS_API_URL,
  responseType: 'json',
});

const middlewares = [Thunk, ReduxPromise, axiosMiddleware(client)];
// For Redux Chrome Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(rootReducer, initialState, enhancer);

export default store;
