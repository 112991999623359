import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const ViewShiftNotes = lazy(() => import('ShiftNote/pages/ViewShiftNotes'));
const DownloadPDF = lazy(() => import('Core/pages/DownloadPDF'));

export default [
  <GuardedRoute allowedRoles={['CS', 'CR']} path="/shift-notes" exact>
    <ViewShiftNotes />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CS', 'CR']} path="/shift-notes/download" exact>
    <DownloadPDF />
  </GuardedRoute>,
];
