const initialState = {
  pastInvoices: [], // for CS
  unpaidInvoices: [], // for CS
  invoices: [], // for CR, contains both paid and unapid invoices
  isFetchingInvoices: false,
  allInvoicesFetched: false,
  isLoading: false,
  invoicesError: null,
};

export default function InvoiceStore(state = initialState, action) {
  switch (action.type) {
    case 'SET_PAST_INVOICES':
      return {
        ...state,
        pastInvoices: action.payload,
      };
    case 'APPEND_PAST_INVOICES':
      return {
        ...state,
        pastInvoices: state.pastInvoices.concat(action.payload),
      };
    case 'REMOVE_PAST_INVOICE':
      return {
        ...state,
        pastInvoices: state.pastInvoices.filter((invoice) => invoice.id !== action.payload),
      };
    case 'SET_UNPAID_INVOICES':
      return {
        ...state,
        unpaidInvoices: action.payload,
      };
    case 'APPEND_UNPAID_INVOICES':
      return {
        ...state,
        unpaidInvoices: state.unpaidInvoices.concat(action.payload),
      };
    case 'REMOVE_UNPAID_INVOICE':
      return {
        ...state,
        unpaidInvoices: state.unpaidInvoices.filter((invoice) => invoice.id !== action.payload),
      };
    case 'SET_INVOICES':
      return {
        ...state,
        invoices: action.payload,
      };
    case 'APPEND_INVOICES':
      return {
        ...state,
        invoices: state.invoice.concat(action.payload),
      };
    case 'REMOVE_INVOICE':
      return {
        ...state,
        invoices: state.invoices.filter((invoice) => invoice.id !== action.payload),
      };
    case 'INVOICES_LOADING':
      return {
        ...state,
        isFetchingInvoices: action.payload,
      };
    case 'ALL_INVOICES_LOADED':
      return {
        ...state,
        allInvoicesFetched: action.payload,
      };
    case 'INVOICE_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'INVOICE_MARK_AS_CLAIMED':
      // eslint-disable-next-line no-case-declarations
      const data = state.pastInvoices.map((invoice) => {
        if (invoice.id === action.payload) {
          const inv = { ...invoice, ndisClaimed: true, ndisClaimedAt: new Date() };

          return inv;
        }

        return invoice;
      });

      return {
        ...state,
        pastInvoices: data,
      };
    case 'INVOICE_ERROR':
      return {
        ...state,
        invoicesError: action.payload,
      };
    case 'RESET_INVOICE_ERROR':
      return {
        ...state,
        invoicesError: null,
      };
    default:
      return state;
  }
}
