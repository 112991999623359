import React from 'react';
import SectionHeader from 'Home/components/SectionHeader/SectionHeader';
import WorkCard from 'Home/components/ReadyHelpSection/ReadyHelpCard/ReadyHelpCard';
import './ReadyHelpSection.scss';
import cooking from 'assets/img/icons/Home/cooking.png';
import domesticAssistance from 'assets/img/icons/Home/domestic-assistance.png';
import foodprep from 'assets/img/icons/Home/foodprep.png';
import outAndAbout from 'assets/img/icons/Home/out-and-about.png';
import independence from 'assets/img/icons/Home/independence.png';
import personalCare from 'assets/img/icons/Home/personal-care.png';
import routines from 'assets/img/icons/Home/routines.png';
import socialSupport from 'assets/img/icons/Home/social-support.png';

const cards = [
  { id: 1, icon: cooking, title: 'Cooking' },
  { id: 2, icon: domesticAssistance, title: 'Domestic assistance' },
  { id: 3, icon: foodprep, title: 'Meal preparation' },
  { id: 4, icon: outAndAbout, title: 'Getting out and about' },
  { id: 5, icon: socialSupport, title: 'Social support and companionship' },
  { id: 6, icon: personalCare, title: 'Personal care' },
  { id: 7, icon: independence, title: 'Maintain an independent life' },
  { id: 8, icon: routines, title: 'Help with morning and evening routines' },
];

export default function ReadyHelpSection({ title }) {
  return (
    <div className="ready-help-section">
      <SectionHeader text={title} />

      <ul className="card-list">
        {cards.map((card) => (
          <WorkCard
            img={card.icon}
            title={card.title}
            key={card.id}
          />
        ))}
      </ul>
    </div>
  );
}
