import { dayAvailability } from 'Job/services/helpers';

const initialJobValue = {
  // create a job
  supportType: null,
  careFor: null,
  searcherRole: null,
  location: '',
  locationType: 'ANY',
  gender: null,
  experience: [],
  tasks: [],
  other_requirements: [],
  languages: [],
  availability: [...dayAvailability],
  approxHoursPerDay: null,
  approxHoursPerWeek: null,
  hourlyRate: null,
  approxStartDate: null,
  payingForCareType: 'NONE',
  title: '',
  referralId: null,
  htmlDescription: [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ],
  ndisFundingCode: null,
  goal: '',
  isGoalVisible: true,
  behaviourOfConcern: '',
  restrictivePractices: 'UNSURE',
  clientName: null,
  invoiceDescription: null,
  addressForInvoice: null,
  disableWorkerNotification: false,
  disableJobBoardPosting: false,
  retryJobPost: false,
};

const initialState = {
  ...initialJobValue,
  jobPostErrors: {},
  createJobAlert: null,
  createJobStatus: null,
  jobs: [],
  activeJobs: [],
  hasNoJobs: false,
  jobApplications: null,
  updatedJob: null,
  isLoadingJobs: false,
  isLoadingJobApplications: false,
  isUpdating: false,
  hasUpdated: false,
  fetchJobsError: false,
  fetchApplicationsError: false,
  updateError: null,
  jobSearchResults: null,
  isSearching: false,
  jobSearchSorting: '',
  jobSearchAlert: null,
  jobDetails: null,
  jobDetailsAlert: null,
  isJobDetailsLoading: false,
};

export default function JobReducer(state = initialState, action) {
  switch (action.type) {
    case 'CARE_FOR':
      return {
        ...state,
        careFor: action.payload,
      };
    case 'SEARCHER_ROLE':
      return {
        ...state,
        searcherRole: action.payload,
      };
    case 'JOB_LOCATION':
      return {
        ...state,
        location: action.payload,
      };
    case 'CARER_LOCATION':
      return {
        ...state,
        locationType: action.payload,
      };
    case 'CARER_GENDER':
      return {
        ...state,
        gender: action.payload,
      };
    case 'JOB_SUPPORT_TYPE':
      return {
        ...state,
        supportType: action.payload,
      };
    case 'CARER_EXPERIENCE':
      return {
        ...state,
        experience: action.payload,
      };
    case 'JOB_BOC':
      return {
        ...state,
        behaviourOfConcern: action.payload,
      };
    case 'JOB_RESTRICTIVE_PRACTICES':
      return {
        ...state,
        restrictivePractices: action.payload,
      };
    case 'CARER_TASKS':
      return {
        ...state,
        tasks: action.payload,
      };
    case 'CARER_OTHER_REQUIREMENTS':
      return {
        ...state,
        other_requirements: action.payload,
      };
    case 'CARER_LANGUAGE_REQUIREMENTS':
      return {
        ...state,
        languages: action.payload,
      };
    case 'JOB_AVAILABILITY':
      return {
        ...state,
        availability: action.payload,
      };
    case 'JOB_APPROX_HOURS_PER_DAY':
      return {
        ...state,
        approxHoursPerDay: action.payload,
      };
    case 'JOB_APPROX_HOURS_PER_WEEK':
      return {
        ...state,
        approxHoursPerWeek: action.payload,
      };
    case 'JOB_HOURLY_RATE':
      return {
        ...state,
        hourlyRate: action.payload,
      };
    case 'JOB_APPROX_START_DATE':
      return {
        ...state,
        approxStartDate: action.payload,
      };
    case 'JOB_PAYMENT_TYPE':
      return {
        ...state,
        payingForCareType: action.payload,
      };
    case 'JOB_TITLE':
      return {
        ...state,
        title: action.payload,
      };
    case 'JOB_REFERRAL_ID':
      return {
        ...state,
        referralId: action.payload,
      };
    case 'JOB_DESCRIPTION':
      return {
        ...state,
        htmlDescription: action.payload,
      };
    case 'JOB_NDIS_FUNDING_CODE':
      return {
        ...state,
        ndisFundingCode: action.payload,
      };
    case 'JOB_GOAL':
      return {
        ...state,
        goal: action.payload,
      };
    case 'JOB_GOAL_VISIBLE':
      return {
        ...state,
        isGoalVisible: action.payload,
      };
    case 'JOB_CLIENT_NAME':
      return {
        ...state,
        clientName: action.payload,
      };
    case 'JOB_INVOICE_DESCRIPTION':
      return {
        ...state,
        invoiceDescription: action.payload,
      };
    case 'JOB_INVOICE_ADDRESS':
      return {
        ...state,
        addressForInvoice: action.payload,
      };
    case 'JOB_DISABLE_WORKER_NOTIFICATION':
      return {
        ...state,
        disableWorkerNotification: action.payload,
      };
    case 'JOB_DISABLE_JOB_BOARD_POSTING':
      return {
        ...state,
        disableJobBoardPosting: action.payload,
      };
    case 'RETRY_JOB_POST':
      return {
        ...state,
        retryJobPost: action.payload,
      };
    case 'RESET_JOB_POST':
      return {
        ...state,
        ...initialJobValue,
        hasUpdated: false,
        createJobStatus: null,
        createJobAlert: null,
      };
    case 'JOB_POST_ERROR':
      return {
        ...state,
        jobPostErrors: action.payload,
      };
    case 'SET_JOBS':
      return {
        ...state,
        jobs: action.payload,
      };
    case 'SET_ACTIVE_JOBS':
      return {
        ...state,
        activeJobs: action.payload || [],
      };
    case 'SET_JOB_APPLICATIONS':
      return {
        ...state,
        jobApplications: action.payload,
      };
    case 'JOB_APPLICATIONS_LOADING':
      return {
        ...state,
        isLoadingJobApplications: action.payload,
      };
    case 'JOB_APPLICATIONS_ERROR':
      return {
        ...state,
        fetchApplicationsError: action.payload,
      };
    case 'SET_NO_JOBS':
      return {
        ...state,
        hasNoJobs: action.payload,
      };
    case 'INSERT_JOB':
      return {
        ...state,
        jobs: [...state.jobs, action.payload],
      };
    case 'JOB_POST_EDIT':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_JOB':
      return {
        ...state,
        updatedJob: action.payload,
        jobs: state.jobs.map((job) => {
          if (job.id === action.payload.id) {
            const updatedJob = { ...job, jobDetails: { ...action.payload.jobDetails } };

            return updatedJob;
          }

          return job;
        }),
      };
    case 'JOB_UPDATING':
      return {
        ...state,
        isUpdating: action.payload,
      };
    case 'JOB_UPDATED':
      return {
        ...state,
        hasUpdated: true,
      };
    case 'JOB_UPDATE_ERROR':
      return {
        ...state,
        updateError: action.payload,
      };
    case 'RESET_JOB_UPDATE_ERROR':
      return {
        ...state,
        updateError: null,
      };
    case 'DELETE_JOB':
      return {
        ...state,
        jobs: state.jobs.filter((job) => job.id !== action.payload),
      };
    case 'LOADING_JOBS':
      return {
        ...state,
        isLoadingJobs: action.payload,
      };
    case 'FETCH_JOBS_ERROR':
      return {
        ...state,
        fetchJobsError: action.payload,
      };
    case 'CREATE_JOB_STATUS':
      return {
        ...state,
        createJobStatus: action.payload,
      };
    case 'INSERT_JOB_ERROR':
    case 'INSERT_JOB_SUCCESS':
      return {
        ...state,
        createJobAlert: action.payload,
      };
    case 'RESET_INSERT_JOB_ALERT':
      return {
        ...state,
        createJobAlert: null,
      };
    case 'JOB_CONTRACT_CREATED':
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.id === action.payload.jobId) {
            return {
              ...job,
              contracts: [...job.contracts, action.payload.contract],
            };
          }

          return job;
        }),
      };
    case 'JOB_AWARDED':
      return {
        ...state,
        jobs: state.jobs.map((job) => {
          if (job.id === action.payload.jobId) {
            return {
              ...job,
              jobAward: [...job.jobAward, action.payload],
            };
          }

          return job;
        }),
      };
    case 'SEARCHING_JOBS':
      return {
        ...state,
        isSearching: action.payload,
      };
    case 'SEARCH_JOB_RESULTS':
      return {
        ...state,
        jobSearchResults: action.payload,
      };
    case 'SEARCH_JOB_APPEND_RESULTS':
      return {
        ...state,
        jobSearchResults: state.jobSearchResults.concat(action.payload),
      };
    case 'SEARCH_JOB_SORTING':
      return {
        ...state,
        jobSearchSorting: action.payload,
      };
    case 'SEARCH_JOB_ALERT':
      return {
        ...state,
        jobSearchAlert: action.payload,
      };
    case 'RESET_SEARCH_JOB_ALERT':
      return {
        ...state,
        jobSearchAlert: null,
      };
    case 'JOB_DETAILS_LOADING':
      return {
        ...state,
        isJobDetailsLoading: action.payload,
      };
    case 'JOB_DETAILS_DATA':
      return {
        ...state,
        jobDetails: action.payload,
      };
    case 'JOB_DETAILS_ALERT':
      return {
        ...state,
        jobDetailsAlert: action.payload,
      };
    case 'JOB_DETAILS_ALERT_RESET':
      return {
        ...state,
        jobDetailsAlert: null,
      };
    default:
      return state;
  }
}
