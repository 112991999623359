import React from 'react';
import './SafetyCard.scss';

export default function SafetyCard({ img, title, description }) {
  return (
    <article className="safety-card">
      <img src={img} alt="" loading="lazy" width="100" height="100" />

      <header>
        <h1 className="title">{title}</h1>
      </header>

      <p className="description">{description}</p>
    </article>
  );
}
