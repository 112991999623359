import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const AgedCareServices = lazy(() => import('StaticPages/AgedCare/pages'));
const HomeCarePackagePage = lazy(() => import('StaticPages/HomeCarePackage/pages'));
const PlanPartnersPage = lazy(() => import('StaticPages/PlanPartners/pages'));
const QuickRequestPage = lazy(() => import('StaticPages/QuickRequest/pages'));

export default [
  <Route path="/services/aged-care-workers" exact component={AgedCareServices} />,
  <Route path="/home-care-package" exact component={HomeCarePackagePage} />,
  <Route path="/planpartners" exact component={PlanPartnersPage} />,
  <Route path="/quick-request" exact component={QuickRequestPage} />,
];
