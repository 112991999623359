const initialState = {
  isSignupInProgress: false,
  signupAlert: null,
  accountExists: false,
  isSignupSuccessful: false,
  profile: null,
  profileLoading: false,
  updateProfilePayload: null,
  profileUpdating: false,
  profileUpdated: false,
  profileError: null,
  reviews: null,
  reviewsFetchError: false,
  bankAccounts: [],
  bankAccountsLoading: false,
  stripeIdentityVerificationPending: false,
  stripeIdentityErrors: null,
  isBusinessHubOpen: false,
};

export default function CarerReducer(state = initialState, action) {
  switch (action.type) {
    case 'CARER_SIGNUP_LOADING':
      return {
        ...state,
        isSignupInProgress: action.payload,
      };
    case 'CARER_SIGNUP_ALERT':
      return {
        ...state,
        signupAlert: action.payload,
      };
    case 'CARER_SIGNUP_ALERT_RESET':
      return {
        ...state,
        signupAlert: null,
        accountExists: false,
      };
    case 'CARER_ACCOUNT_EXISTS':
      return {
        ...state,
        accountExists: action.payload,
      };
    case 'CARER_SIGNUP_SUCCESS':
      return {
        ...state,
        isSignupSuccessful: action.payload,
      };
    case 'CARER_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'PROFILE_LOADING':
      return {
        ...state,
        profileLoading: action.payload,
      };
    case 'CARER_PROFILE_UPDATE_PAYLOAD':
      return {
        ...state,
        updateProfilePayload: action.payload,
      };
    case 'PROFILE_UPDATING':
      return {
        ...state,
        profileUpdating: action.payload,
      };
    case 'CARER_PROFILE_UPDATED':
      return {
        ...state,
        profileUpdated: action.payload,
      };
    case 'PROFILE_ERROR':
      return {
        ...state,
        profileError: action.payload,
      };
    case 'PROFILE_ERROR_RESET':
      return {
        ...state,
        profileError: null,
      };
    case 'RESET_CARER_PROFILE':
      return {
        ...state,
        profile: null,
      };
    case 'CARER_REVIEWS':
      return {
        ...state,
        reviews: action.payload,
      };
    case 'CARER_REVIEWS_ERROR':
      return {
        ...state,
        reviewsFetchError: action.payload,
      };
    case 'CARER_BANK_ACCOUNTS':
      return {
        ...state,
        bankAccounts: action.payload,
      };
    case 'CARER_BANK_ACCOUNTS_LOADING':
      return {
        ...state,
        bankAccountsLoading: action.payload,
      };
    case 'STRIPE_IDENTITY_VERIFICATION_PENDING':
      return {
        ...state,
        stripeIdentityVerificationPending: action.payload,
      };
    case 'STRIPE_IDENTITY_ERRORS':
      return {
        ...state,
        stripeIdentityErrors: action.payload,
      };
    case 'TOGGLE_BUSINESS_HUB_MENU':
      return {
        ...state,
        isBusinessHubOpen: action.payload,
      };
    default:
      return state;
  }
}
