import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ModalDialog from 'Core/components/ModalDialog';
import subscribe from 'Core/services/newsletter';
import { GTMTrackEvent } from 'Core/services/event-tracking';
import './NewsletterSub.scss';

export default function NewsletterSub() {
  const [email, setEmail] = useState(null);
  const [type, setType] = useState('careseeker');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (e) => {
    const data = {
      email,
      subscriptionType: type,
    };

    e.preventDefault();

    try {
      await subscribe(data);

      GTMTrackEvent('Newsletter Subscription', { category: 'Footer Submit', label: `Newsletter type: ${type}` });

      setModalTitle('Newsletter subscription');
      setModalText('Thank you for subscribing to our newsletter!');
      setShowModal(true);
      setEmail('');
      setType('careseeker');
    } catch (err) {
      setModalTitle('Newsletter subscription error');
      setModalText('We were unable to subscribe you to our newsletter at this time. '
        + 'Please retry in a bit, we regret the inconvenience.');
      setShowModal(true);
    }
  };

  return (
    <section className="newsletter">
      <header>
        <h1 className="mb-3 h5 fw-bold">Sign up to our newsletter</h1>
      </header>

      <Form name="newsletter-form" role="form" onSubmit={onSubmit}>
        <Form.Group controlId="nl-email" className="mb-4">
          <Form.Label className="visually-hidden">Your email address</Form.Label>

          <InputGroup>
            <Form.Control
              type="email"
              name="nl-email"
              placeholder="Enter your email here..."
              size="sm"
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button variant="primary" size="sm" type="submit" aria-label="Subscribe">
              Sign Up
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Check
            inline
            label="Care Seeker"
            type="radio"
            name="sub-type"
            id="cs"
            value="careseeker"
            checked={type === 'careseeker'}
            onChange={(e) => { setType(e.target.value); }}
          />

          <Form.Check
            inline
            label="Care Worker"
            type="radio"
            name="sub-type"
            id="cr"
            value="careworker"
            checked={type === 'careworker'}
            onChange={(e) => { setType(e.target.value); }}
          />

          <Form.Check
            inline
            label="Industry Professional"
            type="radio"
            name="sub-type"
            id="professional"
            value="industryProfessional"
            checked={type === 'industryProfessional'}
            onChange={(e) => { setType(e.target.value); }}
          />
        </Form.Group>
      </Form>

      <ModalDialog
        title={modalTitle}
        message={modalText}
        type="alert"
        show={showModal}
        onDismiss={() => setShowModal(false)}
      />
    </section>
  );
}
