const initialState = {
  isLoading: false,
  alertMessage: null,
  scAccount: null,
  linkedAccounts: null,
  isLoggingIn: false,
  isSidebarOpen: false,
  clientId: null,
};

export default function SCStore(state = initialState, action) {
  switch (action.type) {
    case 'SC_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SC_ALERT_MESSAGE':
      return {
        ...state,
        alertMessage: action.payload,
      };
    case 'SC_ALERT_MESSAGE_RESET':
      return {
        ...state,
        alertMessage: null,
      };
    case 'SC_ACCOUNT':
      return {
        ...state,
        scAccount: action.payload,
      };
    case 'SC_RESET_SIGNUP':
      return {
        ...state,
        isLoading: false,
        alertMessage: null,
        scAccount: null,
      };
    case 'SC_LINKED_ACCOUNTS':
      return {
        ...state,
        linkedAccounts: action.payload,
      };
    case 'SC_LOGGING_IN':
      return {
        ...state,
        isLoggingIn: action.payload,
      };
    case 'SC_SIDEBAR_TOGGLE':
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    case 'SC_NEW_CLIENT_ID':
      return {
        ...state,
        clientId: action.payload,
      };
    case 'SC_NEW_CLIENT_ID_RESET':
      return {
        ...state,
        clientId: null,
      };
    default:
      return state;
  }
}
