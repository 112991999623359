const initialState = {
  name: '',
  phone: '',
  email: '',
  institutionName: '',
  ndisNumber: '',
  planStartingDate: null,
  planEndingDate: null,
  dateOfBirth: null,
};

export default function ProviderStore(state = initialState, action) {
  switch (action.type) {
    case 'SET_PROVIDER_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'SET_PROVIDER_PHONE':
      return {
        ...state,
        phone: action.payload,
      };
    case 'SET_PROVIDER_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'SET_PROVIDER_ORG':
      return {
        ...state,
        institutionName: action.payload,
      };
    case 'SET_NDIS_NUMBER':
      return {
        ...state,
        ndisNumber: action.payload,
      };
    case 'SET_PROVIDER_PLAN_START_DATE':
      return {
        ...state,
        planStartingDate: action.payload,
      };
    case 'SET_PROVIDER_PLAN_END_DATE':
      return {
        ...state,
        planEndingDate: action.payload,
      };
    case 'SET_PROVIDER_DOB':
      return {
        ...state,
        dateOfBirth: action.payload,
      };
    case 'SET_PROVIDER':
      return {
        ...state,
        name: action.payload.name || '',
        email: action.payload.email || '',
        phone: action.payload.phone || '',
        institutionName: action.payload.institutionName || '',
        ndisNumber: action.payload.ndisNumber || '',
        planStartingDate: action.payload.planStartingDate || null,
        planEndingDate: action.payload.planEndingDate || null,
        dateOfBirth: action.payload.dateOfBirth || null,
      };
    case 'RESET_PROVIDER':
      return {
        ...state,
        name: '',
        phone: '',
        email: '',
        institutionName: '',
        ndisNumber: '',
        planStartingDate: null,
        planEndingDate: null,
        dateOfBirth: null,
      };
    default:
      return state;
  }
}
