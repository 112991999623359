import React, { lazy } from 'react';
import GuardedRoute from 'Auth/containers/GuardedRoute';

const JobSearch = lazy(() => import('JobSearch/pages/JobSearchPage'));

export default [
  <GuardedRoute allowedRoles={['CR']} path="/find-a-job" exact>
    <JobSearch />
  </GuardedRoute>,

  <GuardedRoute allowedRoles={['CR']} path="/jobs/search" exact>
    <JobSearch />
  </GuardedRoute>,
];
