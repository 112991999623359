import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import './GetStartedOptions.scss';

const options = [
  { value: 'cs', label: 'Looking for new care or support', path: '/careseeker/post-a-job' },
  { value: 'cw', label: 'A support worker', path: '/careworker' },
  { value: 'sc', label: 'A support coordinator', path: '/support-coordinator' },
  { value: 'pr', label: 'A provider organisation', path: '/providers' },
];

export default function GetStartedOptions() {
  const [label, setLabel] = useState('I am...');
  const [value, setValue] = useState('');
  const history = useHistory();

  const onSelected = (val, lbl) => {
    setLabel(lbl);
    setValue(val);
  };

  const onGetStarted = () => {
    if (value) {
      const option = options.find((opt) => opt.value === value);

      history.push(option.path);
    }
  };

  return (
    <InputGroup className="get-started-options">
      <Dropdown>
        <Dropdown.Toggle variant="select" size="lg" className="selected-option">
          {label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {options.map((option) => (
            <Dropdown.Item onClick={() => onSelected(option.value, option.label)} key={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <Button size="lg" onClick={onGetStarted}>
        Get Started
      </Button>
    </InputGroup>
  );
}
