import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import OffCanvas from 'react-bootstrap/Offcanvas';
import { clearAliasCredentials } from 'Auth/services';
import { internalLinks, externalLinks } from 'SupportCoordinator/services/links';
import './SCSidebar.scss';

export default function SCSidebar() {
  const { isSidebarOpen } = useSelector((store) => store.SCStore);
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch({
      type: 'SC_SIDEBAR_TOGGLE',
      payload: !isSidebarOpen,
    });
  };

  const onClicked = () => {
    dispatch(clearAliasCredentials());
    toggleSidebar();
  };

  return (
    <OffCanvas show={isSidebarOpen} onHide={toggleSidebar} placement="end" className="sc-sidebar-container">
      <OffCanvas.Header closeButton closeVariant="white" />

      <OffCanvas.Body>
        <aside>
          <div className="sc-sidebar">
            <section>
              <header>
                <h2>My Account</h2>
              </header>

              <ul className="list-unstyled">
                {internalLinks.map((link) => (
                  <li key={link.to}>
                    <Link to={link.to} onClick={onClicked}>
                      <span className="icon" aria-hidden="true">
                        {link.icon}
                      </span>

                      {link.label}
                    </Link>
                  </li>
                ))}

                {externalLinks.map((link) => (
                  <li key={link.href}>
                    <a href={link.href} target="_blank" rel="noopener noreferrer">
                      <span className="icon" aria-hidden="true">
                        {link.icon}
                      </span>

                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </aside>
      </OffCanvas.Body>
    </OffCanvas>
  );
}
