export const homePage = {
  title: 'Careseekers: Care and Support Workers for Aged Care & Disability Support – NDIS '
    + 'Registered Provider',
  description: 'Choose your own care or support workers. Use your NDIS or Aged Care Funding. '
    + 'Post a job and connect to independent aged care and disability support workers.',
  image: `${process.env.REACT_APP_CS_META_URL}/top_image_2.jpg`,
};

export const carerLanding = {
  title: 'Become a Care Worker | Careseekers',
  description: 'Set your own rates, work your own hours and choose the jobs you want with '
    + 'Careseekers.',
  image: `${process.env.REACT_APP_CS_META_URL}/care_worker.jpg`,
};

export const scLandingPage = {
  title: 'Support Coordinators | Careseekers',
  description: 'Connect directly to care and support workers and have more options about how you manage '
    + 'the process.',
  image: `${process.env.REACT_APP_CS_META_URL}/sup_coord.jpg`,
};

export const providersPage = {
  title: 'Tap into an on-demand workforce, any time, anywhere | Careseekers',
  description: `Are you a provider looking to tap into an on-demand workforce, any time, anywhere?
   At Careseekers, we directly connect you to care and support workers!`,
  image: `${process.env.REACT_APP_CS_META_URL}/provider_landing_main.jpg`,
};

export const loginPage = {
  title: 'Sign in | Careseekers',
  description: 'Sign in to Careseekers to start searching for in-home care workers near you.',
};

export const forgotPasswordPage = {
  title: 'Forgot my password | Careseekers',
  description: 'Reset your Careseekers Account\'s password',
};

export const resetPasswordPage = {
  title: 'Reset your password | Careseekers',
  description: 'Reset your password for your Careseekers account',
};

export const carerSignupPage = {
  title: 'Sign up as a care worker | Careseekers',
  description: 'Sign up as a care worker in minutes and gain access jobs in your area.',
  image: `${process.env.REACT_APP_SC_META_URL}/michelle_profile_pic.jpg`,
};

export const postAJobPage = {
  title: 'Post A Job | Careseekers',
  description: 'Post a free care job on Careseekers and find the right care or support worker that matches '
    + 'your requirements!',
  image: `${process.env.REACT_APP_CS_META_URL}/FirstSlide.jpg`,
};

export const csDashboard = {
  title: 'My Care Dashboard | Careseekers',
  description: '',
};

export const carerProfile = {
  title: 'Care and support worker profile - %name% | Careseekers',
  description: '',
};

export const crDashboard = {
  title: 'Care worker dashboard | Careseekers',
  description: 'Manage your care worker account and perform other tasks using your care worker dashboard',
};

export const updateProfile = {
  title: 'Update your care worker profile | Careseekers',
  description: 'Keep your care worker profile up to date by updating your care expertise, tasks you are willing '
    + 'to perform, your work experience, your preferred suburbs and more to improve your chances of '
    + 'finding that ideal client.',
};

export const carerInvoicesPage = {
  title: 'My Invoices | Careseekers',
  description: '',
};

export const platformFeePage = {
  title: 'Platform Fee Invoices | Careseekers',
  description: '',
};

export const pendingTimesheetsPage = {
  title: 'Job Reports | Careseekers',
  description: '',
};

export const savedTimesheetsPage = {
  title: 'Saved Job Reports | Careseekers',
  description: '',
};

export const workersPage = {
  title: 'Aged Care & Disability Support Workers on Careseekers | Careseekers',
  description: `Careseekers is the online care platform to find aged care workers
   and disability support workers. All workers have been background checked and
   can be used with NDIS funding and aged care funding.`,
};

export const aboutUsPage = {
  title: 'Careseekers  connects you to aged care workers and disability support workers. | Careseekers',
  description: 'Careseekers is an online care platform to find aged care workers '
    + 'and disability support workers. You can use your NDIS funding and aged care funding.',
};

export const ourFoundersPage = {
  title: 'Lauren Hockley and Marissa Sandler are the founders of Careseekers. | Careseekers',
  description: 'Careseekers is an online care platform to find aged care workers and '
    + 'disability support workers. You can use your NDIS funding and aged care funding.',
};

export const contactUsPage = {
  title: 'Contact us | Careseekers',
  description: 'Contact us whether you are a care worker or care seeker. Our friendly customer '
    + 'service representatives are waiting to hear from you.',
};

export const agedCareServicesPage = {
  title: 'Find aged care workers on Careseekers today | Careseekers',
  description: 'Careseekers is an online care platform to find aged care workers and disability support'
    + 'workers. You can use your NDIS funding and aged care funding.',
};

export const mediaCentrePage = {
  title: 'Media enquiries for Careseekers',
  description: `Media enquiries for Careseekers, download our media pack and press releases
  and contact the founders or read pre`,
};

export const covid19Page = {
  title: 'Find out how Careseekers is operating during the Covid-19 pandemic | Careseekers',
  description: 'Find a vaccinated aged care worker and disability support worker on Careseekers.'
    + 'All workers have completed  COVID-19 Infection Control Training.',
};

export const referralsPage = {
  title: 'Find aged care workers and disability support workers for your organisation | Careseekers',
  description: `Make a referral for a disability support worker or aged care worker for your organisation.
   Whether you are a support coordinator in the NDIS, run an aged care business or an NDIS Registered
   provider at Careseekers we can help you.`,
};

export const termsOfUsePage = {
  title: 'Terms of Use. | Careseekers',
  description: 'View our terms of use online.',
};

export const privacyPolicyPage = {
  title: 'Privacy Policy | Careseekers',
  description: 'We take your privacy seriously. View our policies online.',
};

export const complaintPolicyPage = {
  title: 'Complaints, suggestions and feedback policy | Careseekers',
  description: `Careseekers genuinely welcomes and encourages all platform users to provide feedback,
   complaints and suggestions; they are all valued and will help us to develop a better platform.`,
};

export const policeChecPolicykPage = {
  title: 'Police Check Policy | Careseekers',
  description: `Careseekers has followed the Police Certificates Guidelines drafted by the Aged Care Quality
   and Compliance Group and NDIS Quality and Safeguards Commission in drafting the Careseekers Police Check Policy`,
};

export const customerServiceCharterPage = {
  title: 'Customer Service Charter | Careseekers',
  description: `Careseekers Customer Service Charter sets out your rights and how you can expect to be treated
   when you use the Careseekers platform or speak to our team.`,
};

export const codeOfConductPage = {
  title: 'Careseekers Code of Conduct for care workers and care seekers | Careseekers',
  description: `The Careseekers Code of Conduct outlines the behaviour and conduct
  expected of care workers and care seekers.`,
};

export const protectionOfHRPage = {
  title: 'Protection of Human Rights and Freedom from Abuse and Neglect | Careseekers',
  description: `Careseekers respects and works to protect your human rights when you
    are using the Careseekers platform.`,
};

export const insurancePolicyPage = {
  title: 'Careseekers Insurance Policy | Careseekers',
  description: 'View our insurance policy online.',
};

export const submitTimesheetPage = {
  title: 'Submit your hours | Careseekers',
  description: 'Submit your hours to your client to claim your payment',
};

export const myJobsPage = {
  title: 'My Jobs | Careseekers',
  description: '',
};

export const taxAndSuperPage = {
  title: 'Tax & Super | Careseekers',
  description: '',
};

export const messagesPage = {
  title: 'My Messages | Careseekers',
  description: 'Chat with your workers and clients',
};

export const accountDetailsPage = {
  title: 'My Account Details | Careseekers',
  description: 'Manage your Careseekers account details',
};

export const providerDetailsPage = {
  title: 'My Provider Details | Careseekers',
  description: 'Manage your provider details',
};

export const myWorkersPage = {
  title: 'My Care Workers | Careseekers',
  description: 'View and manage your care workers',
};

export const learningHubPage = {
  title: 'Learning Hub | Careseekers',
  description: 'Use the Careseekers Learning Hub to access training courses and receive badges on your '
    + 'care worker profile once you complete each one!',
};

export const feedbackComplaintsPage = {
  title: 'Feedback, Complaints and Incident Reports | Careseekers',
  description: `Careseekers genuinely welcomes and encourages all people using its platform to provide feedback,
   complaints and suggestions.`,
};

export const workHealthSafetyPage = {
  title: 'Work Health and Safety | Careseekers',
  description: 'Careseekers genuinely encourages all users in implementing health and safety practices on their work.',
};

export const feedbackPage = {
  title: 'Give us feedback | Careseekers',
  description: `Careseekers genuinely welcomes and encourages all people using its platform to provide feedback,
  complaints and suggestions.`,
};

export const makeAComplaintPage = {
  title: 'Make a complaint | Careseekers',
  description: `Choose your own care or support workers. Use your NDIS or Aged Care Funding. Post a job and connect
  to independent aged care and disability support workers.`,
};

export const ndisFundedSupportPage = {
  title: 'NDIS Funded Support | Careseekers',
  description: `Choose your own care or support workers. Use your NDIS or Aged Care Funding. Post a job and connect
  to independent aged care and disability support workers.`,
};

export const hcPackagePage = {
  title: 'Home Care Package | Careseekers',
  description: `Choose your own care or support workers. Use your NDIS or Aged Care Funding. Post a job and
  connect to independent aged care and disability support workers.`,
};

export const viewShiftNotes = {
  title: 'My Shift Notes | Careseekers',
  description: 'View and manage your shift notes',
};

export const myCalendarPage = {
  title: 'My Calendar | Careseekers',
  description: 'View and manage your upcoming bookings',
};

export const myBookingsPage = {
  title: 'My Bookings | Careseekers',
  description: 'View and manage your bookings and shifts',
};

export const scRegisterPage = {
  title: 'Sign up as a Support Coordinator | Careseekers',
  description: 'Let Careseekers support you to support your participant to get the most out of our online platform.',
};

export const jobSearchPage = {
  title: 'Jobs Board - Search for care and support jobs | Careseekers',
  description: 'Search for care and support jobs that match your work preferences and connect with clients whose '
    + 'requirements match your skills and areas of expertise.',
};

export const carerSearchPage = {
  title: 'Search for care and support workers | Careseekers',
  description: 'Find a care or support worker that\'s right for you via our search tool.',
  image: `${process.env.REACT_APP_CS_META_URL}/ndis.jpg`,
};

export const mentalHealthSWPage = {
  title: 'Find mental health support workers on Careseekers today | Careseekers',
  description: 'Find mental health support workers on Careseekers.',
};

export const disabilitySWPage = {
  title: 'Find disability support workers on Careseekers today | Careseekers',
  description: 'Find disability support workers on Careseekers.',
};

export const carerWelcomePage = {
  title: 'Welcome to Careseekers! Create your worker profile | Careseekers',
  description: 'Create your care worker profile and start connecting with our community of care seekers.',
};

export const testimonialsPage = {
  title: 'Testimonials from our members | Careseekers',
  description: 'Read about what our community members have to say about their experiences using our platform!',
};

export const planPartnersPage = {
  title: 'Careseekers & Plan Partners | Careseekers',
  description: 'As a Plan Partners customer, get $250 towards your support worker payments!',
  image: `${process.env.REACT_APP_CS_META_URL}/plan-partners.jpg`,
};

export const quickRequestPage = {
  title: 'Quick Request | Careseekers',
  description: 'Do you need to know if we have workers available in a particular area? Send us your '
    + 'request and find out quickly!',
};
