import React from 'react';
import Alert from 'react-bootstrap/Alert';
import './AlertMessage.scss';

export default function AlertMessage({ error, actions = null }) {
  if (error) {
    return (
      <Alert variant={error.type || 'info'} className="alert-message">
        {error.title && (<Alert.Heading>{error.title}</Alert.Heading>)}

        <p className={actions !== null ? 'mb-3' : 'mb-0'}>{error.message}</p>

        {actions !== null && (
          <>
            {actions}
          </>
        )}
      </Alert>
    );
  }

  return null;
}
