const initialState = {
  pendingTimesheets: [], // for statuses 'submitted' and 'resubmitted'
  savedTimesheets: [], // for status 'saved'
  isFetchingTimesheets: false,
  allTimesheetsFetched: false,
  isDeleting: false,
  timesheetsError: null,
  jobReportAlert: null,
  jobReportStatus: null,
  isApproved: false,
  updatedTimesheet: null,
  bookings: null,
  isBookingsLoading: false,
};

export default function TimesheetStore(state = initialState, action) {
  switch (action.type) {
    case 'SET_PENDING_TIMESHEETS':
      return {
        ...state,
        pendingTimesheets: action.payload,
      };
    case 'APPEND_PENDING_TIMESHEETS':
      return {
        ...state,
        pendingTimesheets: state.pendingTimesheets.concat(action.payload),
      };
    case 'REMOVE_PENDING_TIMESHEET':
      return {
        ...state,
        pendingTimesheets: state.pendingTimesheets.filter((timesheet) => timesheet.id !== action.payload),
      };
    case 'REMOVE_PENDING_TIMESHEETS':
      // Remove multiple timesheets from the array (action.payload will be a list of timesheet IDs to remove)
      return {
        ...state,
        pendingTimesheets: state.pendingTimesheets.filter((timesheet) => action.payload.indexOf(timesheet.id) === -1),
      };
    case 'SET_SAVED_TIMESHEETS':
      return {
        ...state,
        savedTimesheets: action.payload,
      };
    case 'APPEND_SAVED_TIMESHEETS':
      return {
        ...state,
        savedTimesheets: state.savedTimesheets.concat(action.payload),
      };
    case 'REMOVE_SAVED_TIMESHEET':
      return {
        ...state,
        savedTimesheets: state.savedTimesheets.filter((timesheet) => timesheet.id !== action.payload),
      };
    case 'TIMESHEETS_LOADING':
      return {
        ...state,
        isFetchingTimesheets: action.payload,
      };
    case 'ALL_TIMESHEETS_LOADED':
      return {
        ...state,
        allTimesheetsFetched: action.payload,
      };
    case 'JOB_REPORT_IS_DELETING':
      return {
        ...state,
        isDeleting: action.payload,
      };
    case 'JOB_REPORT_DELETED':
      return {
        ...state,
        pendingTimesheets: state.pendingTimesheets.filter((ts) => ts.id !== action.payload),
      };
    case 'TIMESHEET_ERROR':
      return {
        ...state,
        timesheetsError: action.payload,
      };
    case 'RESET_TIMESHEET_ERROR':
      return {
        ...state,
        timesheetsError: null,
      };
    case 'JOB_REPORT_STATUS':
      return {
        ...state,
        jobReportStatus: action.payload,
      };
    case 'JOB_REPORT_STATUS_RESET':
      return {
        ...state,
        jobReportStatus: null,
      };
    case 'TIMESHEET_IS_APPROVED':
      return {
        ...state,
        isApproved: action.payload,
      };
    case 'JOB_REPORT_ALERT':
      return {
        ...state,
        jobReportAlert: action.payload,
      };
    case 'JOB_REPORT_ALERT_RESET':
      return {
        ...state,
        jobReportAlert: null,
      };
    case 'TIMESHEET_UPDATED':
      return {
        ...state,
        updatedTimesheet: action.payload,
      };
    case 'SUBMIT_TIMESHEET_BOOKINGS':
      return {
        ...state,
        bookings: action.payload,
      };
    case 'TIMESHEET_BOOKINGS_LOADING':
      return {
        ...state,
        isBookingsLoading: action.payload,
      };
    case 'SUBMIT_TIMESHEET_RESET':
      return {
        ...state,
        bookings: null,
        isBookingsLoading: false,
      };
    default:
      return state;
  }
}
