export const agedCare = [
  { label: 'Aged care - General', value: 'AGEDGEN' },
  { label: 'Dementia', value: 'DEMENTIA' },
  { label: 'Parkinsons', value: 'PARKINSONS' },
  { label: 'Frail / limited mobility', value: 'FRAIL' },
];

export const disability = [
  { label: 'Disability support - Adult', value: 'CFDA' },
  { label: 'Disability support - Child', value: 'CFDC' },
  { label: 'Cerebral Palsy', value: 'CP' },
  { label: 'Motor Neuron Diseases', value: 'MND' },
  { label: 'Acquired Brain Injuries', value: 'ABI' },
  { label: 'MS', value: 'MS' },
  { label: 'Autism', value: 'AUTISM' },
  { label: 'Down syndrome', value: 'DS' },
  { label: 'Intellectual Disability', value: 'ID' },
  { label: 'Spinal cord injury', value: 'SCI' },
  { label: 'Vision impairment', value: 'VI' },
  { label: 'Disability support - Other', value: 'DISABILITY_OTHER' },
];

export const generalCare = [
  { label: 'Post-operative care', value: 'POC' },
  { label: 'Palliative care', value: 'PC' },
  { label: 'Child care', value: 'CHCR' },
];

export const mentalHealth = [
  { label: 'Depression', value: 'DEPRESSION' },
  { label: 'Anxiety', value: 'ANXIETY' },
  { label: 'Psycho social disorder', value: 'PSYSOC' },
  { label: 'Schizophrenia', value: 'SCHIZO' },
  { label: 'Bipolar Disorder', value: 'BIPOLAR' },
  { label: 'OCD', value: 'OCD' },
  { label: 'Mental Health - Other', value: 'MENTOTHER' },
];

export const aroundTheHouseTasks = [
  { label: 'Cleaning services', value: 'CTD' },
  { label: 'Domestic assistance (light duties only)', value: 'CTDL' },
  { label: 'Cooking', value: 'COOK' },
  { label: 'Meal prep', value: 'MEAL' },
  { label: 'Admin', value: 'ADM' },
  { label: 'Computer & IT Support', value: 'CAIS' },
];

export const oneToOneSupportTasks = [
  { label: 'Personal care', value: 'CTP' },
  { label: 'Social companionship', value: 'CTS' },
  { label: 'Australian Sign Language', value: 'AUSLAN' },
  { label: 'Grooming (hair, make-up etc.)', value: 'HAM' },
  { label: 'Therapy Supports (OT, speech, physio)', value: 'TS' },
  { label: 'Continence management', value: 'CM' },
  { label: 'PEG feeding', value: 'PEG' },
  { label: 'Hoists & transfers', value: 'HAT' },
];

export const outAndAboutTasks = [
  { label: 'Errands / Outings', value: 'CTE' },
  { label: 'Community access', value: 'CA' },
  { label: 'Keeping fit', value: 'FIT' },
  { label: 'Art therapy', value: 'ART' },
  { label: 'Music therapy', value: 'MT' },
  { label: 'Skills Development', value: 'SKILLDEV', title: 'Travel training, setting routines etc.' },
];

export const miscSkills = [
  { label: 'Own Car', value: 'OC', type: 'req' },
  { label: 'Have driving license', value: 'HDL', type: 'req' },
  { label: 'Willing to drive', value: 'WTD', type: 'req' },
  { label: 'Willing to work with pets', value: 'WWP', type: 'req' },
  { label: 'Working with Children Check', value: 'WWC', type: 'req' },
  { label: 'Willing to work with smokers', value: 'WWS', type: 'req' },
];

export const getExperience = (additionalSkills) => {
  const experiences = [...agedCare, ...disability, ...generalCare, ...mentalHealth];
  const experienceRequired = experiences.filter((exp) => {
    const skill = additionalSkills.find((sk) => sk.code === exp.value);

    return skill !== undefined;
  });

  return experienceRequired.map((exp) => exp.value);
};

export const getJobTasks = (additionalSkills) => {
  const tasks = [...aroundTheHouseTasks, ...outAndAboutTasks, ...oneToOneSupportTasks];
  const tasksRequired = tasks.filter((task) => {
    const skill = additionalSkills.find((sk) => sk.code === task.value);

    return skill !== undefined;
  });

  return tasksRequired.map((task) => task.value);
};

export const getOtherRequirements = (additionalSkills) => {
  const otherRequirements = miscSkills.filter((ms) => {
    const skill = additionalSkills.find((sk) => sk.code === ms.value);

    return skill !== undefined;
  });

  return otherRequirements;
};

export const getSupportType = (additionalSkills) => {
  const skillCodes = additionalSkills.map((sk) => sk.code);

  const aged = agedCare.filter((item) => skillCodes.includes(item.value));

  if (aged.length) {
    return 'agedCare';
  }

  const disabilitySupport = disability.filter((item) => skillCodes.includes(item.value));

  if (disabilitySupport.length) {
    return 'disabilitySupport';
  }

  const mentalHealthSupport = mentalHealth.filter((item) => skillCodes.includes(item.value));

  if (mentalHealthSupport.length) {
    return 'mentalHealth';
  }

  return 'generalCare';
};

export const getSkillType = (skillCodes) => {
  const aged = agedCare.filter((item) => skillCodes.includes(item.value));

  if (aged.length) {
    return 'agedCare';
  }

  const disabilitySupport = disability.filter((item) => skillCodes.includes(item.value));

  if (disabilitySupport.length) {
    return 'disability';
  }

  const mentalHealthSupport = mentalHealth.filter((item) => skillCodes.includes(item.value));

  if (mentalHealthSupport.length) {
    return 'mentalHealth';
  }

  return 'general';
};
