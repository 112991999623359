const initialState = {
  csAccount: null,
  csAccountError: null,
  signupData: {},
  isSignupInProgress: false,
  isProfileLoading: false,
  isProfileUpdating: false,
  profileError: null,
};

export default function CareSeekerReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CS_ACCOUNT':
      return {
        ...state,
        csAccount: action.payload,
      };
    case 'RESET_CS_ACCOUNT':
      return {
        ...state,
        csAccount: null,
      };
    case 'SET_CS_ACCOUNT_ERROR':
      return {
        ...state,
        csAccountError: action.payload,
      };
    case 'SET_SIGNUP_DATA':
      return {
        ...state,
        signupData: action.payload,
      };
    case 'RESET_SIGNUP_DATA':
      return {
        ...state,
        signupData: {},
      };
    case 'APPEND_SIGNUP_DATA':
      return {
        ...state,
        signupData: { ...state.signupData, ...action.payload },
      };
    case 'SIGNUP_IN_PROGRESS':
      return {
        ...state,
        isSignupInProgress: action.payload,
      };
    case 'CS_PROFILE_LOADING':
      return {
        ...state,
        isProfileLoading: action.payload,
      };
    case 'CS_UPDATE_PROFILE':
      return {
        ...state,
        isProfileUpdating: action.payload,
      };
    case 'CS_LOAD_PROFILE_ERROR':
    case 'CS_UPDATE_PROFILE_ERROR':
      return {
        ...state,
        profileError: action.payload,
      };
    case 'CS_LOAD_PROFILE_ERROR_RESET':
    case 'CS_UPDATE_PROFILE_ERROR_RESET':
      return {
        ...state,
        profileError: null,
      };
    default:
      return state;
  }
}
