const initialState = {
  currentContract: null,

  /* create contract */
  supportRequired: '',
  supportDetails: '',
  weeklyRate: '',
  saturdayRate: '',
  sundayRate: '',
  publicHolidayRate: '',
  eveningCareRate: '',
  overnightCareRate: '',
  travelExpense: '',
  endDate: null,
  cancellationPolicy: '',
  terminationPolicy: '',
  note: '',
  emergencyContacts: [
    { name: '', relationship: '', phone: '' },
  ],
};

export default function ContractStore(state = initialState, action) {
  switch (action.type) {
    case 'SET_CONTRACT':
      return {
        ...state,
        supportRequired: action.payload.supportRequired || '',
        supportDetails: action.payload.supportDetails || '',
        weeklyRate: action.payload.hourlyRateForWeekdays || '',
        saturdayRate: action.payload.saturdayRate || '',
        sundayRate: action.payload.sundayRate || '',
        publicHolidayRate: action.payload.rateForPublicHoliday || '',
        eveningCareRate: action.payload.rateForEveningCare || '',
        overnightCareRate: action.payload.rateForOverniteCare || '',
        travelExpense: action.payload.travelExpenseRate || '',
        endDate: action.payload.endDate || null,
        cancellationPolicy: action.payload.cancellationPolicy || '',
        terminationPolicy: action.payload.terminationPolicy || '',
        note: action.payload.note || '',
        emergencyContacts: action.payload.emergencyContacts ? action.payload.emergencyContacts.map((c) => {
          const contact = {
            name: c.nameOfContact,
            relationship: c.relationshipToCareseeker,
            phone: c.phoneNumber,
          };

          return contact;
        }) : [],
      };
    case 'RESET_CONTRACT':
      return {
        ...state,
        currentContract: null,
      };
    case 'SET_SUPPORT_REQUIRED':
      return {
        ...state,
        supportRequired: action.payload,
      };
    case 'SET_SUPPORT_DETAILS':
      return {
        ...state,
        supportDetails: action.payload,
      };
    case 'SET_EXPIRY_DATE':
      return {
        ...state,
        endDate: action.payload,
      };
    case 'SET_WEEKLY_RATE':
      return {
        ...state,
        weeklyRate: action.payload,
      };
    case 'SET_SATURDAY_RATE':
      return {
        ...state,
        saturdayRate: action.payload,
      };
    case 'SET_SUNDAY_RATE':
      return {
        ...state,
        sundayRate: action.payload,
      };
    case 'SET_PUBLIC_HOLIDAY_RATE':
      return {
        ...state,
        publicHolidayRate: action.payload,
      };
    case 'SET_EVENING_CARE_RATE':
      return {
        ...state,
        eveningCareRate: action.payload,
      };
    case 'SET_OVERNIGHT_CARE_RATE':
      return {
        ...state,
        overnightCareRate: action.payload,
      };
    case 'SET_TRAVEL_EXPENSE':
      return {
        ...state,
        travelExpense: action.payload,
      };
    case 'SET_CANCELLATION_POLICY':
      return {
        ...state,
        cancellationPolicy: action.payload,
      };
    case 'SET_TERMINATION_POLICY':
      return {
        ...state,
        terminationPolicy: action.payload,
      };
    case 'SET_NOTES':
      return {
        ...state,
        note: action.payload,
      };
    case 'SET_EMERGENCY_CONTACTS':
      return {
        ...state,
        emergencyContacts: action.payload,
      };
    case 'RESET_CREATE_CONTRACT':
      return {
        ...state,
        supportRequired: '',
        supportDetails: '',
        endDate: null,
        weeklyRate: '',
        saturdayRate: '',
        sundayRate: '',
        publicHolidayRate: '',
        eveningCareRate: '',
        overnightCareRate: '',
        travelExpense: '',
        cancellationPolicy: '',
        terminationPolicy: '',
        note: '',
        emergencyContacts: [],
      };
    default:
      return state;
  }
}
