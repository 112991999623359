import { client as axios } from 'store';
import angie from 'assets/img/landing/home/angie.png';
import angieWebp from 'assets/img/landing/home/angie.webp';
import eva from 'assets/img/landing/home/eva.png';
import evaWebp from 'assets/img/landing/home/eva.webp';
import george from 'assets/img/landing/home/george.png';
import georgeWebp from 'assets/img/landing/home/george.webp';
import grace from 'assets/img/landing/home/grace.png';
import graceWebp from 'assets/img/landing/home/grace.webp';
import joriz from 'assets/img/landing/home/joriz.png';
import jorizWebp from 'assets/img/landing/home/joriz.webp';
import josh from 'assets/img/landing/home/josh.png';
import joshWebp from 'assets/img/landing/home/josh.webp';
import judy from 'assets/img/landing/home/judy.png';
import judyWebp from 'assets/img/landing/home/judy.webp';
import miaAndDeb from 'assets/img/landing/home/mia_and_deb.png';
import miaAndDebWebp from 'assets/img/landing/home/mia_and_deb.webp';
import pratima from 'assets/img/landing/home/pratima.png';
import pratimaWebp from 'assets/img/landing/home/pratima.webp';
import sue from 'assets/img/landing/home/sue.png';
import sueWebp from 'assets/img/landing/home/sue.webp';
import yani from 'assets/img/landing/home/yani.png';
import yaniWebp from 'assets/img/landing/home/yani.webp';

const IG_API_URL = process.env.REACT_APP_CS_INSTAGRAM_API_URL;
const IG_TKN = process.env.REACT_APP_CS_INSTAGRAM_TOKEN;
const defaultIGPosts = [
  {
    id: 1,
    media_type: 'CAROUSEL_ALBUM',
    permalink: 'https://www.instagram.com/p/CZ8Hh9iJy-E',
    media_url: '/images/instagram/insta_1',
  },
  {
    id: 2,
    media_type: 'IMAGE',
    permalink: 'https://www.instagram.com/p/CaQcq6lpB6K',
    media_url: '/images/instagram/insta_2',
  },
  {
    id: 3,
    media_type: 'CAROUSEL_ALBUM',
    permalink: 'https://www.instagram.com/p/CY42CoLFqHf',
    media_url: '/images/instagram/insta_3',
  },
  {
    id: 4,
    media_type: 'CAROUSEL_ALBUM',
    permalink: 'https://www.instagram.com/p/CYXbT-GAYaN',
    media_url: '/images/instagram/insta_4',
  },
  {
    id: 5,
    media_type: 'CAROUSEL_ALBUM',
    permalink: 'https://www.instagram.com/p/CZK7NhWpYlD',
    media_url: '/images/instagram/insta_5',
  },
  {
    id: 6,
    media_type: 'CAROUSEL_ALBUM',
    permalink: 'https://www.instagram.com/p/CXfibDvJzrK',
    media_url: '/images/instagram/insta_6',
  },
];

export const getPosts = () => async (dispatch) => {
  dispatch({ type: 'INSTA_POSTS_REQUEST' });

  try {
    const { data } = await axios({
      method: 'GET',
      url: `${IG_API_URL}${IG_TKN}`,
      transformRequest: (d, headers) => {
        // eslint-disable-next-line no-param-reassign
        delete headers['auth-token'];

        return d;
      },
    });

    if (data && data.data && data.data.length) {
      dispatch({ type: 'INSTA_POSTS_SUCCESS', payload: data.data });
    } else {
      dispatch({ type: 'INSTA_POSTS_SUCCESS', payload: defaultIGPosts });
    }
  } catch (e) {
    dispatch({ type: 'INSTA_POSTS_FAILURE', payload: defaultIGPosts });
  }
};

export const matchingPairs = [
  {
    id: 1,
    csMain: georgeWebp,
    cs: george,
    crMain: angieWebp,
    cr: angie,
  },
  {
    id: 2,
    csMain: miaAndDebWebp,
    cs: miaAndDeb,
    crMain: pratimaWebp,
    cr: pratima,
  },
  {
    id: 3,
    csMain: judyWebp,
    cs: judy,
    crMain: yaniWebp,
    cr: yani,
  },
  {
    id: 4,
    csMain: graceWebp,
    cs: grace,
    crMain: evaWebp,
    cr: eva,
  },
  {
    id: 5,
    csMain: joshWebp,
    cs: josh,
    crMain: jorizWebp,
    cr: joriz,
  },
  {
    id: 6,
    csMain: sueWebp,
    cs: sue,
    crMain: angieWebp,
    cr: angie,
  },
];
