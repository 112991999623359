import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initialize, identify, isInitialized } from 'Core/services/fullstory';

export default function Fullstory() {
  const { currentUser, isLoggedIn } = useSelector((store) => store.AuthReducer);

  useEffect(() => {
    window._fs_debug = false;
    window._fs_host = 'fullstory.com';
    window._fs_org = process.env.REACT_APP_CS_FULLSTORY_ID;
    window._fs_namespace = 'FullStory';

    initialize();
  }, []);

  useEffect(() => {
    if (isInitialized()) {
      if (isLoggedIn) {
        identify(currentUser);
      } else {
        identify(false);
      }
    }
  }, [isLoggedIn, currentUser]);

  return null;
}
