import React from 'react';
import { Link } from 'react-router-dom';
import './WorkerCard.scss';

const states = {
  'New South Wales': 'NSW',
  Victoria: 'VIC',
  Queensland: 'QLD',
  'Western Australia': 'WA',
  'South Australia': 'SA',
  'Australian Capital Territory': 'ACT',
  Tasmania: 'TAS',
};

const getLocation = (suburb, state) => {
  const location = `${suburb}, ${states[state]}`;

  return location;
};

export default function WorkerCard({ img, alt, carerId, name, suburb, state, blurb }) {
  const getBlurb = (text) => {
    if (!text) {
      return '';
    }

    if (text.length > 100) {
      return `${text.substring(0, 100)}...`;
    }

    return text;
  };

  return (
    <article className="worker-card">
      <div className="photo">
        <Link to={`/carer/profile/${carerId}`}>
          <img src={img} alt={alt} title={`View ${name}'s profile`} />
        </Link>
      </div>

      <div className="details">
        <header>
          <h2>
            <Link to={`/carer/profile/${carerId}`} title={`View ${name}'s profile`}>{name}</Link>
          </h2>
        </header>

        <div className="suburb">{getLocation(suburb, state)}</div>

        <p className="blurb">{getBlurb(blurb)}</p>
      </div>
    </article>
  );
}
