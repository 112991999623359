import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  IoPersonCircleOutline,
  IoReceiptOutline,
  IoTimeOutline,
  IoCalendarOutline,
  IoChatboxOutline,
  IoSaveSharp,
  IoBarChartSharp,
  IoHourglassSharp,
  IoPencilSharp,
  IoHelpCircleOutline,
} from 'react-icons/io5';
import { FaFileInvoiceDollar, FaReceipt, FaFileInvoice, FaFileExcel, FaBook } from 'react-icons/fa';
import './BusinessHub.scss';

export default function BusinessHub() {
  const { currentUser } = useSelector((store) => store.AuthReducer);

  return (
    <div className="business-hub">
      <h3>My Account</h3>

      <ul className="list-unstyled">
        <li>
          <Link to={`/carer/profile/${currentUser.id}`}>
            <span className="icon" aria-hidden="true">
              <IoPersonCircleOutline />
            </span>

            View Profile
          </Link>
        </li>

        <li>
          <Link to="/my-jobs">
            <span className="icon" aria-hidden="true">
              <IoReceiptOutline />
            </span>

            Jobs & Contracts
          </Link>
        </li>

        <li>
          <Link to="/submit-timesheet">
            <span className="icon" aria-hidden="true">
              <IoHourglassSharp />
            </span>

            Submit Hours
          </Link>
        </li>

        <li>
          <Link to="/saved-hours">
            <span className="icon" aria-hidden="true">
              <IoSaveSharp />
            </span>

            Saved Hours
          </Link>
        </li>

        <li className="has-submenu">
          <Link to="/unapproved-invoices">
            <span className="icon" aria-hidden="true">
              <FaFileInvoice />
            </span>
            Billing
            <ul className="submenu">
              <li>
                <Link to="/unapproved-invoices">
                  <span className="icon" aria-hidden="true">
                    <FaFileExcel />
                  </span>
                  Job Reports
                </Link>
              </li>
              <li>
                <Link to="/paid-invoices">
                  <span className="icon" aria-hidden="true">
                    <FaFileInvoiceDollar />
                  </span>
                  Remittances
                </Link>
              </li>
              <li>
                <Link to="/platform-fee">
                  <span className="icon" aria-hidden="true">
                    <FaReceipt />
                  </span>
                  Platform Fee Invoices
                </Link>
              </li>
            </ul>
          </Link>
        </li>

        <li>
          <Link to="/my-bookings">
            <span className="icon" aria-hidden="true">
              <IoTimeOutline />
            </span>

            Bookings
          </Link>
        </li>

        <li>
          <Link to="/shift-notes">
            <span className="icon" aria-hidden="true">
              <IoPencilSharp />
            </span>

            Shift Notes
          </Link>
        </li>

        <li>
          <Link to="/my-calendar">
            <span className="icon" aria-hidden="true">
              <IoCalendarOutline />
            </span>

            Calendar
          </Link>
        </li>

        <li>
          <Link to="/messages">
            <span className="icon" aria-hidden="true">
              <IoChatboxOutline />
            </span>

            Messages
          </Link>
        </li>

        <li>
          <Link to="/tax-and-super">
            <span className="icon" aria-hidden="true">
              <IoBarChartSharp />
            </span>

            Tax & Super
          </Link>
        </li>

        <li>
          <a href="https://helpcentre.careseekers.com.au/154156-care-and-support-workers">
            <span className="icon" aria-hidden="true">
              <IoHelpCircleOutline />
            </span>

            Help Centre
          </a>
        </li>

        <li>
          <Link to="/learning-hub">
            <span className="icon" aria-hidden="true">
              <FaBook />
            </span>

            Learning Hub
          </Link>
        </li>
      </ul>
    </div>
  );
}
