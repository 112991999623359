import { createBrowserHistory } from 'history';
import { logout } from 'Auth/services';

export const history = createBrowserHistory();

const shouldRedirect = (url) => {
  // Do not redirect to login on 401 on these API paths
  const whitelist = ['signin', 'auth/verify', 'invoice/'];
  let result = true;

  for (let i = 0; i < whitelist.length; i++) {
    if (url.includes(whitelist[i])) {
      result = false;

      break;
    }
  }

  return result;
};

export default async ({ store, client }) => {
  client.interceptors.request.use((config) => {
    const updatedConfig = { ...config };

    if (!config.headers['auth-token']) {
      const { AuthReducer } = store.getState();
      let authToken;

      if (AuthReducer.currentUser) {
        // check for alias login by support coordinator
        authToken = AuthReducer.currentUser.aliasToken;

        if (!authToken) {
          // try normal auth token
          authToken = AuthReducer.currentUser.authToken;
        }

        if (authToken) {
          updatedConfig.headers['auth-token'] = authToken;
        }
      }
    }

    return updatedConfig;
  }, (error) => Promise.reject(error));

  client.interceptors.response.use(
    (res) => res,
    (error) => {
      const err = error;

      err.code = error.response.status;

      if (error.response.status === 401) {
        if (shouldRedirect(error.response.config.url)) {
          store.dispatch(logout());

          if (history.location.pathname !== '/login') {
            history.push('/login', {
              referrer: history.location.pathname,
              message: 'Please login with your Careseekers account to continue.',
              showLogin: true,
            });
          }
        }
      } else if (error.response.status === 403) {
        store.dispatch(logout());
        history.push('/login', {
          referrer: history.location.pathname,
          message: 'You are not authorized to perform that action.',
          showLogin: true,
        });
      }

      return Promise.reject(err);
    },
  );
};
