import React from 'react';
import { IoAlbums, IoVideocam } from 'react-icons/io5';
import './InstagramCard.scss';

export default function InstagramCard({ post, isDefault = false }) {
  return (
    <div className="instagram-card">
      <a target="_blank" rel="noreferrer noopener" href={post.permalink}>
        {post.media_type === 'CAROUSEL_ALBUM' && <IoAlbums />}

        {post.media_type === 'VIDEO' && <IoVideocam />}

        {!isDefault && (
          <>
            {(post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM') && (
              <img src={post.media_url} alt="Careseekers Instagram Post" width="300" height="300" loading="lazy" />
            )}

            {post.media_type === 'VIDEO' && (
              <video width="300" height="300" loading="lazy" controls>
                <source src={post.media_url} type="video/mp4" />
                <track kind="captions" srcLang="en" label="English" />
                Your browser does not support the video tag.
              </video>
            )}
          </>
        )}

        {isDefault && (
          <picture>
            <source srcSet={`${post.media_url}.webp`} type="image/webp" />
            <source srcSet={`${post.media_url}.png`} type="image/png" />
            <img src={`${post.media_url}.png`} alt="" width="300" height="300" loading="lazy" />
          </picture>
        )}
      </a>
    </div>
  );
}
