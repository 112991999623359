const initialState = {
  bookings: null, // all bookings
  createPayload: [],
  confirmedBookings: null,
  unconfirmedBookings: null,
  cancelledBookings: null,
  conflictBookings: null,
  isBookingsLoading: false,
  isConfirmedLoading: false,
  isUnconfirmedLoading: false,
  isCancelledLoading: false,
  isConflictLoading: false,
  isSaving: false,
  isUpdating: false,
  hasConfirmed: false,
  hasCancelled: false,
  bookingsAlert: null,
  bookingsUpdateAlert: null,
  greenlightedWorkers: [],
  greenLightAlert: null,
  isGreenLightLoading: false,
};

export default function BookingStore(state = initialState, action) {
  switch (action.type) {
    case 'SET_BOOKINGS':
      return {
        ...state,
        bookings: action.payload,
      };
    case 'RESET_BOOKINGS':
      return {
        ...state,
        bookings: null,
      };
    case 'BOOKINGS_LOADING':
      return {
        ...state,
        isBookingsLoading: action.payload,
      };
    case 'ADD_NEW_BOOKING':
      return {
        ...state,
        createPayload: state.createPayload.concat(action.payload),
      };
    case 'SET_CONFIRMED_BOOKINGS':
      return {
        ...state,
        confirmedBookings: action.payload,
      };
    case 'RESET_CONFIRMED_BOOKINGS':
      return {
        ...state,
        confirmedBookings: null,
      };
    case 'CONFIRMED_BOOKINGS_LOADING':
      return {
        ...state,
        isConfirmedLoading: action.payload,
      };
    case 'SET_UNCONFIRMED_BOOKINGS':
      return {
        ...state,
        unconfirmedBookings: action.payload,
      };
    case 'RESET_UNCONFIRMED_BOOKINGS':
      return {
        ...state,
        unconfirmedBookings: null,
      };
    case 'UNCONFIRMED_BOOKINGS_LOADING':
      return {
        ...state,
        isUnconfirmedLoading: action.payload,
      };
    case 'SET_CANCELLED_BOOKINGS':
      return {
        ...state,
        cancelledBookings: action.payload,
      };
    case 'RESET_CANCELLED_BOOKINGS':
      return {
        ...state,
        cancelledBookings: null,
      };
    case 'CANCELLED_BOOKINGS_LOADING':
      return {
        ...state,
        isCancelledLoading: action.payload,
      };
    case 'SET_CONFLICT_BOOKINGS':
      return {
        ...state,
        conflictBookings: action.payload,
      };
    case 'RESET_CONFLICT_BOOKINGS':
      return {
        ...state,
        conflictBookings: null,
      };
    case 'CONFLICT_BOOKINGS_LOADING':
      return {
        ...state,
        isConflictLoading: action.payload,
      };
    case 'BOOKING_UPDATING':
      return {
        ...state,
        isUpdating: action.payload,
      };
    case 'BOOKING_CONFIRMED':
      return {
        ...state,
        hasConfirmed: action.payload,
      };
    case 'RESET_BOOKING_CONFIRMED':
      return {
        ...state,
        hasConfirmed: false,
      };
    case 'BOOKING_CANCELLED':
      return {
        ...state,
        hasCancelled: action.payload,
      };
    case 'RESET_BOOKING_CANCELLED':
      return {
        ...state,
        hasCancelled: false,
      };
    case 'SET_BOOKINGS_ALERT':
      return {
        ...state,
        bookingsAlert: action.payload,
      };
    case 'RESET_BOOKINGS_ALERT':
      return {
        ...state,
        bookingsAlert: null,
      };
    case 'SET_BOOKINGS_UPDATE_ALERT':
      return {
        ...state,
        bookingsUpdateAlert: action.payload,
      };
    case 'RESET_BOOKINGS_UPDATE_ALERT':
      return {
        ...state,
        bookingsUpdateAlert: null,
      };
    case 'CONFIRMED_TO_CANCELLED':
      return {
        ...state,
        confirmedBookings: state.confirmedBookings ? state.confirmedBookings.map((booking) => {
          if (booking.jobScheduleId === action.payload) {
            if (state.cancelledBookings) {
              state.cancelledBookings.unshift({ ...booking, scheduleResponseStatus: 'CANCELLED' });
            }

            return { ...booking, scheduleResponseStatus: 'CANCELLED' };
          }

          return booking;
        }) : null,
      };
    case 'CONFIRMED_TO_UNCONFIRMED':
      // Update a booking date/time
      return {
        ...state,
        confirmedBookings: state.confirmedBookings ? state.confirmedBookings.map((booking) => {
          if (booking.jobScheduleId === action.payload.jobScheduleId) {
            if (state.unconfirmedBookings) {
              state.unconfirmedBookings.unshift({ ...action.payload, scheduleResponseStatus: 'UNCONFIRMED' });
            }

            return { ...action.payload, scheduleResponseStatus: 'UNCONFIRMED' };
          }

          return booking;
        }) : null,
      };
    case 'UNCONFIRMED_TO_CANCELLED':
      return {
        ...state,
        unconfirmedBookings: state.unconfirmedBookings ? state.unconfirmedBookings.map((booking) => {
          if (booking.jobScheduleId === action.payload) {
            if (state.cancelledBookings) {
              state.cancelledBookings.unshift({ ...booking, scheduleResponseStatus: 'CANCELLED' });
            }

            return { ...booking, scheduleResponseStatus: 'CANCELLED' };
          }

          return booking;
        }) : null,
      };
    case 'UNCONFIRMED_TO_CONFIRMED':
      return {
        ...state,
        unconfirmedBookings: state.unconfirmedBookings ? state.unconfirmedBookings.map((booking) => {
          if (booking.jobScheduleId === action.payload) {
            if (state.confirmedBookings) {
              state.confirmedBookings.unshift({ ...booking, scheduleResponseStatus: 'CONFIRMED' });
            }

            return { ...booking, scheduleResponseStatus: 'CONFIRMED' };
          }

          return booking;
        }) : null,
      };
    case 'UNCONFIRMED_TO_UNCONFIRMED':
      // Update an unconfirmed booking date/time
      return {
        ...state,
        unconfirmedBookings: state.unconfirmedBookings.map((booking) => {
          if (booking.jobScheduleId === action.payload.jobScheduleId) {
            return { ...action.payload, scheduleResponseStatus: 'UNCONFIRMED' };
          }

          return booking;
        }),
      };
    case 'GREENLIGHTED_WORKERS_DATA':
      return {
        ...state,
        greenlightedWorkers: action.payload,
      };
    case 'GREENLIGHT_IS_LOADING':
      return {
        ...state,
        isGreenLightLoading: action.payload,
      };
    case 'GREENLIGHT_ALERT':
      return {
        ...state,
        greenLightAlert: action.payload,
      };
    case 'GREENLIGHT_ALERT_RESET':
      return {
        ...state,
        greenLightAlert: null,
      };
    default:
      return state;
  }
}
